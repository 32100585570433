import React from "react";
import {Columns, Form, Heading, Icon} from "react-bulma-components";
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import {faPoundSign} from "@fortawesome/free-solid-svg-icons";
import SaleCustomerDetails from "./SaleCustomerDetails";



export default function SaleInfo({financeAgreement, onChange, endpoint}){
    const getProfit = () => {
        return (financeAgreement.sold_price - financeAgreement.estimated_value + ((financeAgreement.remaining_balance - financeAgreement.hp_remaining_interest) - financeAgreement.finance_early_settlement_amount_paid)) ? (financeAgreement.sold_price - financeAgreement.estimated_value + ((financeAgreement.remaining_balance - financeAgreement.hp_remaining_interest) - financeAgreement.finance_early_settlement_amount_paid)) : 0;
    };

    const getCashFlow = () => {
        return (financeAgreement.sold_price - financeAgreement.finance_early_settlement_amount_paid) ? (financeAgreement.sold_price - financeAgreement.finance_early_settlement_amount_paid) : 0;
    };
    return(
        <div>
            <Columns>
            <Columns.Column>
              <Heading size={4}>Sale Info</Heading>
            </Columns.Column>
          </Columns>
          <Columns>
            <Columns.Column>
              <Form.Field>
                <Form.Label>
                  Sale Date
                </Form.Label>
                <Form.Control>
                  <Form.Input
                    type="date"
                    name="internal_sale_date"
                    onChange={onChange}
                    value={financeAgreement.internal_sale_date || ""}/>
                </Form.Control>
              </Form.Field>
            </Columns.Column>
            <Columns.Column>
              <Form.Field>
                <Form.Label>
                  Depreciated Value / Cost of Sale
                </Form.Label>
                <Form.Control>
                  <Form.Input
                    type="text"
                    value={
                      financeAgreement.finance_type !== 'GRANT_FINANCE' ?
                          (financeAgreement.estimated_value > 0
                        ? financeAgreement.estimated_value.toFixed(2)
                        : 0) : financeAgreement.estimated_grant_value
                    }
                    disabled
                    readOnly
                  />
                  <Icon align={"left"} className={"small-icon"}>
                    <FontAwesomeIcon icon={faPoundSign}/>
                  </Icon>
                </Form.Control>
              </Form.Field>
            </Columns.Column>
            <Columns.Column>
              <Form.Field>
                <Form.Control>
                  <Form.Label>Invoice Number</Form.Label>
                  <Form.Input
                    type="text"
                    name="internal_invoice_number"
                    onChange={onChange}
                    value={financeAgreement.internal_invoice_number}
                  />
                </Form.Control>
              </Form.Field>
            </Columns.Column>
          </Columns>
          <Columns>
            <Columns.Column>
              <Form.Field>
                <Form.Label>Advertised Price</Form.Label>
                <Form.Control>
                  <Form.Input
                    type="text"
                    name="external_list_price"
                    onChange={onChange}
                    value={financeAgreement.external_list_price}
                    required
                  />
                  <Icon align={"left"} className={"small-icon"}>
                    <FontAwesomeIcon icon={faPoundSign}/>
                  </Icon>
                </Form.Control>
              </Form.Field>
            </Columns.Column>

            <Columns.Column>
              <Form.Field>
                <Form.Label>Sold Price</Form.Label>
                <Form.Control>
                  <Form.Input
                    type="text"
                    name="sold_price"
                    onChange={onChange}
                    value={financeAgreement.sold_price}
                    required
                  />
                  <Icon align={"left"} className={"small-icon"}>
                    <FontAwesomeIcon icon={faPoundSign}/>
                  </Icon>
                </Form.Control>
              </Form.Field>
            </Columns.Column>
            <Columns.Column>
              <Form.Field>
                <Form.Label>Sale Deposit</Form.Label>
                <Form.Control>
                  <Form.Input
                    name={"sale_deposit"}
                    onChange={onChange}
                    type={"number"}
                    value={financeAgreement.sale_deposit}
                  />
                  <Icon align={"left"} className={"small-icon"}>
                    <FontAwesomeIcon icon={faPoundSign}/>
                  </Icon>
                </Form.Control>
              </Form.Field>
            </Columns.Column>
            <Columns.Column>
              <Form.Field>
                <Form.Label>Deposit Taken Date</Form.Label>
                <Form.Control>
                  <Form.Input
                    name={"sale_deposit_date"}
                    onChange={onChange}
                    type={"date"}
                    value={financeAgreement.sale_deposit_date}
                  />
                </Form.Control>
              </Form.Field>
            </Columns.Column>
            <Columns.Column>
              <Form.Field>
                <Form.Label>Profit</Form.Label>
                <Form.Control>
                  <Form.Input
                    type="number"
                    name="profit"
                    value={getProfit().toFixed(2)}
                    disabled
                    readOnly
                  />
                  <Icon align={"left"} className={"small-icon"}>
                    <FontAwesomeIcon icon={faPoundSign}/>
                  </Icon>
                </Form.Control>
              </Form.Field>
            </Columns.Column>
            <Columns.Column>
              <Form.Field>
                <Form.Label>Cash Flow</Form.Label>
                <Form.Control>
                  <Form.Input
                    type="text"
                    name="sold_price"
                    onChange={onChange}
                    value={getCashFlow().toFixed(2)}
                    disabled
                    readOnly
                  />
                  <Icon align={"left"} className={"small-icon"}>
                    <FontAwesomeIcon icon={faPoundSign}/>
                  </Icon>
                </Form.Control>
              </Form.Field>
            </Columns.Column>
            <Columns.Column>
              <Form.Field>
                <Form.Label>
                  Sold Date
                </Form.Label>
                <Form.Control>
                  <Form.Input
                    type="date"
                    name="sold_date"
                    onChange={onChange}
                    value={financeAgreement.sold_date || ""}
                    disabled={true}
                  />
                </Form.Control>
              </Form.Field>
            </Columns.Column>
            <Columns.Column>
              <Form.Field>
                <Form.Control>
                  <Form.Label>Comments</Form.Label>
                  <Form.Input
                    type="text"
                    name="sale_comments"
                    onChange={onChange}
                    value={financeAgreement.sale_comments}
                  />
                </Form.Control>
              </Form.Field>
            </Columns.Column>
            <Columns.Column>
              <Form.Field>
                <Form.Control>
                  <Form.Label>Sold To</Form.Label>
                  <Form.Input
                    type="text"
                    name="sold_to"
                    onChange={onChange}
                    value={financeAgreement.sale_customer ? financeAgreement.sale_customer.display_name : ""}
                    disabled={true}
                  />
                </Form.Control>
              </Form.Field>
            </Columns.Column>
          </Columns>
          <Columns>
            <Columns.Column>
              <SaleCustomerDetails finance={financeAgreement} onChange={onChange} endpoint={endpoint}/>
            </Columns.Column>
          </Columns>
        </div>
    )



}