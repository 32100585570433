import React, {useState} from "react";
import useEditableTableTextCell from "../../../hooks/useEditableTableTextCell";
import {Button} from "react-bulma-components";


export default function DriverRow({driver, dataFresh, handleChange, handleSave, index, onDelete}){
    const [editable, setEditable] = useState(false);
    const onChange = e => {
        handleChange(e,index)
    }
    const onSave = e => {
        handleSave(driver);
        setEditable(false);
    }
    return (
        <tr>
            <td>
                {useEditableTableTextCell({
                    propName: "name",
                    value: driver.name,
                    onSave,
                    onChange,
                    editable,
                    setEditable,
                    inlineEdit: true
                })}
            </td>
            <td>
                <Button
                    color={"danger"}
                    onClick={() => onDelete(driver)}
                >
                    X
                </Button>
            </td>
        </tr>
    )


}