import React from "react";
import {Picker} from "../../Utils/Picker";


export default function PaymentTypePicker({inlineEdit, onSave, onChange, options, blank, disabled, editable, setEditable, value}){

    return (
        <Picker
            inlineEdit={inlineEdit}
            options={options}
            onChange={onChange}
            onSave={onSave}
            blank={blank}
            disabled={disabled}
            editable={editable}
            setEditable={setEditable}
            name={"payment_type"}
            label={inlineEdit ? "Payment Type": null}
            value={value}
            optionField={"name"}
        />
    )


}