import React from "react";
import {Box, Columns, Form} from "react-bulma-components";
import Collapsible from "react-collapsible";


export default function MovementDetails({onChange, contact}){



    return (
        <Box>
            <Collapsible
                trigger={"Movement Details"}
                triggerOpenedClassName={"title"}
                className={"title"}
            >
                <Columns>
                    <Columns.Column>
                        <Form.Field>
                            <Form.Label>Movement Markup %</Form.Label>
                            <Form.Input
                                type={"number"}
                                value={contact.movement_markup}
                                onChange={onChange}
                                name={"movement_markup"}
                            />
                        </Form.Field>
                    </Columns.Column>
                    <Columns.Column></Columns.Column>
                    <Columns.Column></Columns.Column>
                    <Columns.Column></Columns.Column>
                </Columns>
            </Collapsible>
        </Box>
    )
}