import React from "react";
import { useJobData } from "../../hooks/useJobData";
import Form from "./Form";
import {getDataFresh, getDepartmentsData, getKeysData} from "../../state/selectors";
import { useSelector } from "react-redux";
import {useDefaultsData} from "../../hooks/useDefaultsData";
import {useDepartmentsData} from "../../hooks/useDepartmentsData";
import {useFittersData} from "../../hooks/useFittersData";
import {useKeysData} from "../../hooks/useKeysData";
import {useWorkTypeData} from "../../hooks/useWorkTypeData";
import {useMovementsRateData} from "../../hooks/useMovementsRateData";
import {useMovementTypesData} from "../../hooks/useMovementTypesData";
import {useDriversData} from "../../hooks/useDriversData";

function JobForm(props) {
  let dataFresh = useSelector(getDataFresh);
  let app_defaults_loaded = useDefaultsData({});
  let job_loaded = useJobData(props.match.params.job, dataFresh);
  let departmentsLoaded = useDepartmentsData({}, dataFresh);
  let departments = useSelector(getDepartmentsData);
  let keys_loaded = useKeysData({});
  let workTypesLoaded = useWorkTypeData({}, dataFresh)
  let movementRatesLoaded = useMovementsRateData({}, dataFresh);
  let movementTypesLoaded = useMovementTypesData({}, dataFresh);
  let driversLoaded = useDriversData({}, dataFresh);
  let keys = useSelector(getKeysData);
  const fittersLoaded = useFittersData({})
  return (
    <Form
      loaded={job_loaded && departmentsLoaded && fittersLoaded && keys_loaded && workTypesLoaded && movementRatesLoaded && movementTypesLoaded && driversLoaded}
      endpoint={props.endpoint}
      history={props.history}
      departments={departments}
      keys={keys}
    />
  );
}

export default JobForm;
