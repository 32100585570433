import React from "react"
import {Picker} from "../../Utils/Picker";

export default function MovementTypePicker({options, value, editable, setEditable, onChange, onSave, disabled, label, inlineEdit}){

    return (
        <Picker
            options={options}
            editable={editable}
            setEditable={setEditable}
            onSave={onSave}
            onChange={onChange}
            disabled={disabled}
            label={label}
            value={value}
            inlineEdit={inlineEdit}
            optionField={"name"}
            name={"movement_type"}
        />
    )


}