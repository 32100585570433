import React from "react";
import {Picker} from "../../Utils/Picker";

export default function DriverPicker({label, options, onChange, blank, disabled, onSave, editable, setEditable, inlineEdit, value}){

    return (
        <Picker
            name={"driver"}
            label={label}
            options={options}
            onChange={onChange}
            onSave={onSave}
            blank={blank}
            disabled={disabled}
            editable={editable}
            setEditable={setEditable}
            optionField={"name"}
            inlineEdit={inlineEdit}
            value={value}
        />
    )



}