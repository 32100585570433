import React, {useState} from "react";
import { Form } from "react-bulma-components";
import { Button } from "react-bulma-components";
import axios from "axios";
import {useDispatch, useSelector} from "react-redux";
import {createSetDataFreshAction} from "../../actions/dataActions";
import {getDataFresh} from "../../state/selectors";
import {NotificationManager} from "react-notifications";
import useEditableTableCostCell from "../../hooks/useEditableTableCostCell";
import useEditableTableDateCell from "../../hooks/useEditableTableDateCell";
import useEditableTableTextCell from "../../hooks/useEditableTableTextCell";
import useSafeSave from "../../hooks/useSafeSave";
import FAIcon from "../Icon/FAIcon";
import useEditableTableTextAreaCell from "../../hooks/useEditableTableTextAreaCell";
import {asyncContainer, Typeahead} from "react-bootstrap-typeahead";
import CreatePartModal from "../PartLookup/CreatePartModal";
import PaymentTypePicker from "../Settings/PaymentTypes/PaymentTypePicker";

const AsyncTypeahead = asyncContainer(Typeahead);

function PartRow({
                   part,
                   index,
                   onPartUpdate,
                   endpoint,
                   showApprove,
                   onSelectCheck,
                   selected,
                   clearSelected,
                   vehicleTypes,
                   approvedView,
                   makes,
                   models,
                   paymentTypes
                 }) {
  const dateFields = ["payment_date", "date_ordered"];
  const dispatch = useDispatch();
  const [editable, setEditable] = useState(false);
  const [lastEdited, setLastEdited] = useState();
  const [createPartModalOpen, setCreatePartModalOpen] = useState(false);
  let dataFresh = useSelector(getDataFresh);

  const paymentTypeObjects =  paymentTypes ? paymentTypes.results.reduce((obj, item) => {
    return {
      ...obj,
      [item["id"]]: item
    };
  }, {}) : {};

  const onPaymentTypeChange = e => {
    const toUpdate = {
      target: {
        name: e.target.name,
        value: paymentTypeObjects[e.target.value]
      }
    }
    onChange(toUpdate);
  }



  const fixEmptyDates = partData => {
    dateFields.forEach(function (date) {
      if (partData[date] === "") {
        partData[date] = null;
      }
    });
    return partData;
  };
  const [isLoading, setLoading] = useState(false);
  const [searchData, setSearchData] = useState([]);
  const [, setTypeahead] = useState();

  const handleSearch = e => {
    setLoading(true);
    axios.get(`/api/contacts?contact_type=SP&search=${e}`).then(resp => {
      setSearchData(resp.data.results);
      setLoading(false);
    });
  };

  const handlePartSupplierSelect = supplier => {
    let newPart = {...part, supplier: supplier[0]};
    makeDirty();
    setLastEdited(new Date());
    onPartUpdate(index, newPart);
  };

  const submitUpdate = approval => {
    part.approved = !!approval;
    const url = endpoint + "parts/update/" + part.id;
    const data = fixEmptyDates(part);
    const conf = {
      method: "put",
      data,
      url
    };

    return axios(conf).then(() => {
      setEditable(false);
      dispatch(createSetDataFreshAction(dataFresh + 1));
    });
  };

  const onPartApprove = e => {
    e.stopPropagation();
    if (part.part_invoice_number.length > 0 && !/[A-Za-z]+/.test(part.part_invoice_number) && part.payment_date) {
      submitUpdate(true);
      clearSelected();
    } else {
      NotificationManager.warning(
        "You must specify an invoice number (numbers only) and payment date before approving!",
        "Invoice Number + Payment Date Required",
        10000
      );
    }
  };

  const onSave = e => {
    return submitUpdate();
  };

  const checkUpdated = () => {
    return axios.get(endpoint + "parts/update/" + part.id).then(newPart => {
      return newPart.data.last_saved === part.last_saved;
    });
  };

  const [makeDirty, safeSave] = useSafeSave(
    onSave,
    lastEdited,
    undefined,
    checkUpdated
  );

  const onChange = e => {
    makeDirty();
    setLastEdited(new Date());
    let newPart = {...part};
    newPart[e.target.name] = e.target.value;
    onPartUpdate(index, newPart);
  };

  const onCheck = e => {
    e.stopPropagation();
    onSelectCheck(part);
  };

  const onCheckboxChange = updatedPart => {
    const url = endpoint + "parts/update/" + part.id;
    const data = fixEmptyDates(updatedPart);
    const conf = {
      method: "put",
      data,
      url
    };

    return axios(conf).then(() => {
      setEditable(false);
      dispatch(createSetDataFreshAction(dataFresh + 1));
    });
  }
  const onStockCheck = e => {
    e.stopPropagation();
    part.from_stock = !part.from_stock;
    return onCheckboxChange(part)
  };


  const vehicle = part.job ? part.job.vehicle : part.quote.vehicle;

  return (
      <tr
          className={"clickable small-row-black"}
          onClick={() =>
              window.open(
                  part.job
                      ? `/editjob/${part.job.id}`
                      : `/editquote/${part.quote.id}`,
                  "_self"
              )
          }
          key={part.id}
      >
        <td>{part.job ? `J${part.job.id}` : `Q${part.quote.id}`}</td>
        <td>{vehicle.registration}</td>
        <td>
          {vehicle.make ? vehicle.make.name : ""} {vehicle.model ? vehicle.model.name : ""}
        </td>
        <td
            onClick={e => {
              e.stopPropagation();
            }}
        >
          <Form.Control>
            <Form.Field className="has-addons">
              <AsyncTypeahead
                  useCache={false}
                  id="typeahead"
                  labelKey="display_name"
                  minLength={2}
                  name={"supplier"}
                  onSearch={handleSearch}
                  onChange={handlePartSupplierSelect}
                  placeholder={"Search Supplier"}
                  options={searchData}
                  ref={typeahead => setTypeahead(typeahead)}
                  className="typeahead"
                  isLoading={isLoading}
                  selected={
                    part.supplier && part.supplier.id ? [part.supplier] : []
                  }
                  disabled={!editable}
              />
              {editable ? (
                  <Form.Control>
                    <Button
                        className="small-row-white"
                        color="success"
                        onClick={onSave}
                    >
                      <FAIcon size="small" icon={["fas", "save"]}></FAIcon>
                    </Button>
                  </Form.Control>
              ) : (
                  <Form.Control>
                    <Button
                        className="small-row-black"
                        onClick={() => setEditable(!editable)}
                    >
                      <FAIcon size="small" icon={["fas", "edit"]}/>
                    </Button>
                  </Form.Control>
              )}
            </Form.Field>
            <p className="small-row-black has-text-centered">
              {part.supplier && part.supplier.parts_contact_number}
            </p>
          </Form.Control>
        </td>
        {useEditableTableTextCell({
          propName: "name",
          value: part.name,
          onChange,
          onSave: safeSave,
          editable,
          setEditable,
          inlineEdit: true
        })}
        {useEditableTableTextCell({
          propName: "part_number",
          value: part.part_number,
          onChange,
          onSave: safeSave,
          editable,
          setEditable,
          inlineEdit: true
        })}
        {useEditableTableCostCell({
          propName: "cost",
          value: part.cost,
          onChange,
          onSave: safeSave,
          editable,
          setEditable,
          inlineEdit: true
        })}
        {useEditableTableDateCell({
          value: part.job ? part.job.workshop_date : "",
        })}
        {useEditableTableDateCell({
          propName: "date_ordered",
          value: part.date_ordered,
          onChange,
          onSave: safeSave,
          editable,
          setEditable,
          inlineEdit: true
        })}
        {<td onClick={(e) => e.stopPropagation()}>
          <PaymentTypePicker
            options={paymentTypes}
            inlineEdit={false}
            blank={true}
            value={part.payment_type}
            editable={editable}
            setEditable={setEditable}
            disabled={!editable}
            onSave={onSave}
            onChange={onPaymentTypeChange}
          />
          </td>
        }
        {useEditableTableTextCell({
          propName: "part_invoice_number",
          value: part.part_invoice_number,
          onChange,
          onSave: safeSave,
          editable,
          setEditable,
          inlineEdit: true
        })}
        {useEditableTableDateCell({
          propName: "payment_date",
          value: part.payment_date,
          onChange,
          onSave: safeSave,
          editable,
          setEditable,
          inlineEdit: true
        })}
        {useEditableTableTextAreaCell({
          propName: "comments",
          value: part.comments,
          onChange,
          onSave: safeSave,
          editable,
          setEditable,
          inlineEdit: true
        })}
        <td>
          <Form.Field>
            <Form.Control className="table-checkbox">
              <Form.Checkbox
                  checked={part.from_stock}
                  onClick={onStockCheck}
                  type="checkbox"
                  name="from_stock"
                  readOnly
              />
            </Form.Control>
          </Form.Field>
        </td>
        <td>
          {part.created_from_lookup ? part.created_from_lookup.stock_volume : 0}
        </td>
        {useEditableTableDateCell({
          value: part.backorder_expected_date,
          propName: "backorder_expected_date",
          onChange,
          onSave: safeSave,
          editable,
          setEditable,
          inlineEdit: true
        })}
        <td>
          {part.job ? (part.job.workshop.stock_kept_at_supplier ? "YES" : "NO") : ""}
        </td>
        {approvedView &&
            <td>{part.approved && part.approver ? `${part.approver.first_name} ${part.approver.last_name}` : ""}</td>}
        <td onClick={e => e.stopPropagation()}>
          <Form.Field>
            <Form.Control className="table-checkbox">
              <Form.Checkbox
                  checked={selected}
                  type="checkbox"
                  onClick={onCheck}
                  readOnly
              />
            </Form.Control>
          </Form.Field>
        </td>
        <td onClick={e => {
          e.stopPropagation()
        }}>
          <Button
              disabled={!!part.created_from_lookup}
              color="warning"
              onClick={(e) => {
                setCreatePartModalOpen(true)
              }}
          >
            <b>+</b>
          </Button>
        </td>

        {showApprove && (
            <td className="float-right">
              <Button
                  color="success"
                  data-testid={`submit-part-approve${part.id}`}
                  onClick={onPartApprove}
              >
                <FAIcon size="small" icon={["fas", "check"]}/>
              </Button>
            </td>
        )}
        <div onClick={e => e.stopPropagation()}>
          <CreatePartModal
              open={createPartModalOpen}
              setOpen={setCreatePartModalOpen}
              endpoint={endpoint}
              historicPart={part}
              vehicleTypes={vehicleTypes}
              makes={makes}
              models={models}
          /></div>
      </tr>
  );
}

export default PartRow;
