import React, {useState} from "react";
import {Box, Columns, Table} from "react-bulma-components";
import MovementRateRow from "./MovementRateRow";
import {useMovementsRateData} from "../../../hooks/useMovementsRateData";
import {useDispatch, useSelector} from "react-redux";
import {getDataFresh, getMovementRatesData} from "../../../state/selectors";
import LoadingSpinner from "../../Utils/Spinner";
import Collapsible from "react-collapsible";
import NewMovementRate from "./NewMovementRate";
import axios from "axios";
import {createSetDataFreshAction, createSetMovementRateDataAction} from "../../../actions/dataActions";
import {NotificationManager} from "react-notifications";


export default function MovementRateView(props){
    const [page, setPage] = useState(1);
    const dispatch = useDispatch();
    let dataFresh = useSelector(getDataFresh);
    let loaded = useMovementsRateData({page}, dataFresh);
    let data = useSelector(getMovementRatesData);


    const onSave = (item) => {
        let url = item.id ? props.endpoint+"movementrate/"+item.id : props.endpoint+"movementrate";
        const conf = {
            url: url,
            method: item.id ? "put": "post",
            data:item
        }
        axios(conf).then(() => {
            dispatch(createSetDataFreshAction(dataFresh + 1));
        }).catch(err => {
            NotificationManager.error("There was an error saving this rate");
        })
    }
    const onDelete = item => {
        const conf = {
            url: props.endpoint+"movementrate/"+item.id,
            method: "delete"
        }
        axios(conf).then(() => {
            dispatch(createSetDataFreshAction(dataFresh + 1));
        }).catch(err => {
            NotificationManager.error("There was an error deleting this rate");
        })
    }
    const onChange = (e,index) => {
        let newRates = [...data.results];
        newRates[index][e.target.name] = e.target.value;
        console.log(newRates);
        dispatch(createSetMovementRateDataAction(newRates));
    }
    if(!loaded){
        return (<LoadingSpinner />);
    }

    return (
        <div>
            <br/>
            <NewMovementRate onSave={onSave}/>
            <Box>
                <Collapsible
                    trigger={"Movement Rates"}
                    triggerOpenedClassName={"title"}
                    className={"title"}
                >
                    <Columns>
                        <Columns.Column>
                            <Table size={"fullwidth"}>
                                <thead>
                                    <tr>
                                        <th>Name</th>
                                        <th>Cost Per Mile</th>
                                        <th>Vehicle Cost</th>
                                        <th>Additional Time Cost</th>
                                        <th>Callout Cost</th>
                                        <th>Comments</th>
                                        <th></th>
                                    </tr>
                                </thead>
                                <tbody>
                                {data.results.map((rate, index) => (
                                    <MovementRateRow
                                        rate={rate}
                                        handleSave={onSave}
                                        index={index}
                                        handleChange={onChange}
                                        onDelete={onDelete}
                                    />
                                ))}
                                </tbody>
                            </Table>
                        </Columns.Column>
                    </Columns>
                </Collapsible>
            </Box>
        </div>
    )

}