import React, {useState} from "react";
import {Button} from "react-bulma-components";
import useEditableTableTextCell from "../../../hooks/useEditableTableTextCell";


export default function PaymentTypeRow({data, index, dataFresh, onDelete, handleChange, onSave}){
    const [editable, setEditable] = useState(false);

    const onChange = e => {
        handleChange(e, index);
    }
    return (
        <tr key={data.id}>
            <td>
                {useEditableTableTextCell({
                    propName: "name",
                    value: data.name,
                    onChange: onChange,
                    onSave: () => {onSave(data); setEditable(false)},
                    editable,
                    setEditable,
                    inlineEdit: true
                })}
            </td>
            <td>
                <Button
                    color={"danger"}
                    onClick={()=> onDelete(data)}
                >
                    X
                </Button>
            </td>
        </tr>
    )
}