import React from "react";
import {usePaymentTypesData} from "../../../hooks/usePaymentTypesData";
import {useDispatch, useSelector} from "react-redux";
import {getDataFresh, getPaymentTypesData} from "../../../state/selectors";
import LoadingSpinner from "../../Utils/Spinner";
import {Box, Columns, Table} from "react-bulma-components";
import Collapsible from "react-collapsible";
import PaymentTypeRow from "./PaymentTypeRow";
import CreatePaymentType from "./CreatePaymentType";
import {createSetDataFreshAction, createSetPaymentTypesDataAction} from "../../../actions/dataActions";
import {NotificationManager} from "react-notifications";
import axios from "axios";

export default function PaymentTypeView(props){
    const dataFresh = useSelector(getDataFresh);
    const dispatch = useDispatch();
    let loaded = usePaymentTypesData({}, dataFresh)
    const data = useSelector(getPaymentTypesData);

    if(!loaded){
        return (<LoadingSpinner/>);
    }
    const onSave = (data) => {
        let url = props.endpoint+"paymenttype";
        if(data.id){
            url = url+"/"+data.id
        }
        const conf = {
            url: url,
            method: data.id ? "put" : "post",
            data
        }
        axios(conf).then(() => {
            dispatch(createSetDataFreshAction(dataFresh + 1));
        }).catch(err => {
            NotificationManager.error("There was an error saving payment type")
        })
    }
    const onDelete = (data) => {
        const conf ={
            url: props.endpoint+"paymenttype/"+data.id,
            method: "delete"
        }
        axios(conf).then(() => {
            dispatch(createSetDataFreshAction(dataFresh + 1));
        }).catch(err => {
            NotificationManager.error("There was an error deleting a payment type");
        })
    }
    const handleChange = (e, index) => {
       let newTypes = [...data.results];
       newTypes[index][e.target.name] = e.target.value;
       dispatch(createSetPaymentTypesDataAction(newTypes));
    }
    return (
        <div>
            <br/>
            <CreatePaymentType onSave={onSave}/>
            <Box>
                <Collapsible
                    trigger={"Payment Types"}
                    triggerOpenedClassName={"title"}
                    className={"title"}
                >
                    <Columns>
                        <Columns.Column>
                            <Table
                                size={"fullwidth"}
                            >
                                <thead>
                                    <tr>
                                        <th>Name</th>
                                        <th></th>
                                    </tr>
                                </thead>
                                <tbody>
                                {data.results.map((type,index) => (
                                    <PaymentTypeRow
                                        index={index}
                                        data={type}
                                        dataFresh={dataFresh}
                                        handleChange={handleChange}
                                        onSave={onSave}
                                        onDelete={onDelete}
                                    />
                                ))}
                                </tbody>
                            </Table>
                        </Columns.Column>
                        <Columns.Column/>
                        <Columns.Column/>
                        <Columns.Column/>
                    </Columns>
                </Collapsible>
            </Box>
        </div>
    )


}