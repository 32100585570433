import React from "react"
import {Box, Columns, Heading, Table} from "react-bulma-components";
import MovementRow from "../Movement/MovementRow";
import {movementCost} from "../../utils";
import {createSetDataFreshAction, createSetMovementsDataAction} from "../../actions/dataActions";
import axios from "axios";
import {NotificationManager} from "react-notifications";
import {useDispatch} from "react-redux";

export default function MovementSection({movements, rateData, dataFresh, endpoint, typeData, movementTypeObjects, driverData, driverObjects}){
    const dispatch = useDispatch();

    const movementRateObjects = rateData.results.reduce((obj, item) => {
        return {
          ...obj,
          [item["id"]]: item
        };
    }, {});


    const onChange = (e, index) => {
        let newMovement = {...movements.results[index], [e.target.name]:e.target.value}
        newMovement = movementCost(newMovement)
        movements.results[index] = newMovement;
        dispatch(createSetMovementsDataAction(movements.results))
    }

    const onRateChange = (e, index) => {
        let toUpdate ={
            target: {
                name: e.target.name,
                value: movementRateObjects[e.target.value]
            }
        }
        onChange(toUpdate, index);
    }

    const onComplete = (data) => {
        const conf = {
            url: endpoint+"movementcomplete/"+data.id,
            method: "put",
            data: data
        };
        axios(conf).then((res) => {
            dispatch(createSetDataFreshAction(dataFresh+1))
        });
    };

    const onDelete = (data) => {
        const conf = {
            url: endpoint+"movement/"+data.id,
            method: "delete"
        }
        axios(conf).then((res) => {
            dispatch(createSetDataFreshAction(dataFresh+1));
        }).catch(() => {
            NotificationManager.error("There was an error deleting that movement");
        })
    };
    const onQuoteToMovement = (data) => {
        const url = endpoint+"movementquote/"+data.id;
        const conf = {
            url: url,
            method: "put"
        }
        axios(conf).then(res => {
            dispatch(createSetDataFreshAction(dataFresh + 1))
        })
    };

    const onSave = (data) => {
        const conf = {
            url: endpoint+"movement/"+data.id,
            method: "put",
            data: data
        }
        axios(conf).then(() => {
            dispatch(createSetDataFreshAction(dataFresh + 1));
        }).catch(()=> {
            NotificationManager.error("There was an error saving the movement")
        })
    };

    return (
        <Box>
            <Columns>
                <Columns.Column>
                    <Heading>Movements</Heading>
                </Columns.Column>
            </Columns>
            <Columns>
                <Columns.Column>
                    <Table
                        size={"fullwidth"}
                    >
                        <thead>
                        <tr>
                            <th>Movement No.</th>
                            <th>Vehicle To Move</th>
                            <th>Type</th>
                            <th>Vehicle Used For Movement</th>
                            <th>Delivery Date</th>
                            <th>Start Address</th>
                            <th>End Address</th>
                            <th>Start Time</th>
                            <th>End Time</th>
                            <th>Customer</th>
                            <th>Driver</th>
                            <th></th>
                        </tr>
                        </thead>
                        <tbody>
                        {movements ? movements.results.map(((movement, index) => (
                            <MovementRow
                                movement={movement}
                                onDelete={onDelete}
                                onChange={onChange}
                                index={index}
                                onSave={onSave}
                                rateOptions={rateData}
                                onRateChange={onRateChange}
                                view={"QUOTE"}
                                quoteToMovement={onQuoteToMovement}
                                onComplete={onComplete}
                                movementTypes={typeData}
                                movementTypeObjects={movementTypeObjects}
                                endpoint={endpoint}
                                isJob={true}
                                disabled={movement.completed}
                                driverObjects={driverObjects}
                                driverOptions={driverData}
                            />
                        ))) : <tr></tr>}
                        </tbody>
                    </Table>
                </Columns.Column>
            </Columns>
        </Box>
    )


}