import {Picker} from "../../../Utils/Picker";
import React from "react";
export default function TransactionReportingCategoryPicker({options, label, onChange, disabled, inlineEdit, onSave, editable, setEditable, value}){


    return (
        <Picker
            options={options}
            optionField={"name"}
            label={label}
            name={"transaction_reporting_category"}
            editable={editable}
            setEditable={setEditable}
            onSave={onSave}
            onChange={onChange}
            disabled={disabled}
            inlineEdit={inlineEdit}
            value={value}
        />
    )


}