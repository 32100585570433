import React, {useState} from "react";
import {Box, Columns, Table} from "react-bulma-components";
import MovementTypeRow from "./MovementTypeRow";
import {useDispatch, useSelector} from "react-redux";
import {getDataFresh, getMovementTypesData, getTransactionReportingCategoriesData} from "../../../state/selectors";
import LoadingSpinner from "../../Utils/Spinner";
import Collapsible from "react-collapsible";
import NewMovementType from "./NewMovementType";
import axios from "axios";
import {createSetDataFreshAction, createSetMovementTypesDataAction} from "../../../actions/dataActions";
import {NotificationManager} from "react-notifications";
import {useMovementTypesData} from "../../../hooks/useMovementTypesData";
import {useTransactionReportingCategoryData} from "../../../hooks/useTransactionReportingCategoryData";


export default function MovementTypeView(props){
    const [page, setPage] = useState(1);
    const dispatch = useDispatch();
    let dataFresh = useSelector(getDataFresh);
    let loaded = useMovementTypesData({page}, dataFresh);
    let transactionCategoriesLoaded = useTransactionReportingCategoryData({}, dataFresh);
    let data = useSelector(getMovementTypesData);
    let transactionCategories = useSelector(getTransactionReportingCategoriesData);


    const onSave = (item) => {
        let url = item.id ? props.endpoint+"movementtype/"+item.id : props.endpoint+"movementtype";
        const conf = {
            url: url,
            method: item.id ? "put": "post",
            data:item
        }
        axios(conf).then(() => {
            dispatch(createSetDataFreshAction(dataFresh + 1));
        }).catch(err => {
            NotificationManager.error("There was an error saving this type");
        })
    }
    const onDelete = item => {
        const conf = {
            url: props.endpoint+"movementtype/"+item.id,
            method: "delete"
        }
        axios(conf).then(() => {
            dispatch(createSetDataFreshAction(dataFresh + 1));
        }).catch(err => {
            NotificationManager.error("There was an error deleting this type");
        })
    }
    const onChange = (e,index) => {
        let newTypes = [...data.results];
        newTypes[index][e.target.name] = e.target.value;
        dispatch(createSetMovementTypesDataAction(newTypes));
    }
    const onTransactionReportingCategoryChange = (e, index) =>{
        const toUpdate = {
            target: {
                name: [e.target.name],
                value: transactionReportingCategoryObjects[e.target.value]
            }
        };
        onChange(toUpdate, index)
    }
    if(!loaded || !transactionCategoriesLoaded){
        return (<LoadingSpinner />);
    }
    let transactionReportingCategoryObjects = transactionCategories.results.reduce((obj, item) => {
      return {
        ...obj,
        [item["id"]]: item
      };
    }, {});
    return (
        <div>
            <NewMovementType onSave={onSave} transactionReportingCategories={transactionCategories} transactionReportingObjects={transactionReportingCategoryObjects}/>
            <Box>
                <Collapsible
                    trigger={"Movement Types"}
                    triggerOpenedClassName={"title"}
                    className={"title"}
                >
                    <Columns>
                        <Columns.Column>
                            <Table size={"fullwidth"}>
                                <thead>
                                    <tr>
                                        <th>Name</th>
                                        <th>Reporting Category</th>
                                        <th></th>
                                    </tr>
                                </thead>
                                <tbody>
                                {data.results.map((type, index) => (
                                    <MovementTypeRow
                                        type={type}
                                        handleSave={onSave}
                                        index={index}
                                        handleChange={onChange}
                                        onDelete={onDelete}
                                        transactionReportingCategories={transactionCategories}
                                        onReportingTypeChange={onTransactionReportingCategoryChange}
                                    />
                                ))}
                                </tbody>
                            </Table>
                        </Columns.Column>
                    </Columns>
                </Collapsible>
            </Box>
        </div>
    )

}