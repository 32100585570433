import React, {useState} from "react";

import {Box} from "react-bulma-components";
import {Heading} from "react-bulma-components";
import {Columns} from "react-bulma-components";
import {Modal} from "react-bulma-components";
import {Section} from "react-bulma-components";
import {Button} from "react-bulma-components";
import {Form} from "react-bulma-components";
import useInput from "../../hooks/useInput";
import {formatDate, getQueryString, readableDate} from "../../utils";
import axios from "axios";
import {NotificationManager} from "react-notifications";
import Spinner from "react-spinner-material";
import useSelect from "../../hooks/useSelect";
import PaymentTypePicker from "../Settings/PaymentTypes/PaymentTypePicker";
import {usePaymentTypesData} from "../../hooks/usePaymentTypesData";
import {getPaymentTypesData} from "../../state/selectors";
import {useSelector} from "react-redux";
import LoadingSpinner from "../Utils/Spinner";

export default function Reports({endpoint}) {

  document.title = "Reports";

  var max = new Date().getFullYear()
  var min = 2019;
  var years = [];

  for (var i = max; i >= min; i--) {
    years.push(i)
  }

  const [month, monthInput] = useSelect({
    label: "Month",
    options: [
      {name: "January", value: 1},
      {name: "February", value: 2},
      {name: "March", value: 3},
      {name: "April", value: 4},
      {name: "May", value: 5},
      {name: "June", value: 6},
      {name: "July", value: 7},
      {name: "August", value: 8},
      {name: "September", value: 9},
      {name: "October", value: 10},
      {name: "November", value: 11},
      {name: "December", value: 12}
    ],
    initialValue: 1,
  });

  const [year, yearInput] = useSelect({
    label: "Year",
    options: years.map((year) => {
      return {name: year, value: year}
    }),
    initialValue: max,
  });
  const [startDate, startDateInput] = useInput({
    type: "date",
    label: "Start Date",
    initialValue: null
  });

  const [paymentTypeStartDate, paymentTypeStartDateInput] = useInput({
    type: "date",
    label: "Start Date",
    initialValue: null
  });

  const [paymentTypeEndDate, paymentTypeEndDateInput] = useInput({
    type: "date",
    label: "End Date",
    initialValue: null
  });
  const paymentTypesLoaded = usePaymentTypesData({});
  const paymentTypes = useSelector(getPaymentTypesData);

  const paymentTypeObjects = paymentTypes ? paymentTypes.results.reduce((obj, item) => {
    return {
      ...obj,
      [item["id"]]: item
    };
  }, {}) : {};


  const [paymentType, setPaymentType] = useState({});
  const [modalOpen, setModalOpen] = useState(false);
  const [reportErrored, setReportErrored] = useState(false);

  const [endDate, endDateInput] = useInput({
    type: "date",
    label: "End Date",
    initialValue: null
  });

  const [monthlyDate, monthlyDateInput] = useInput({
    type: "date",
    label: "Select any date in the month you wish to run the report for",
    initialValue: null
  });

  const onClickNoParams = reportType => {
    const fileName = `${reportType}-${readableDate(formatDate(new Date(Date.now())))}.csv`;
    onClick(reportType, "", fileName)
  };

  const onClickMonthly = reportType => {
    const queryString = getQueryString({year: year, month: month});
    const fileName = `${reportType}-${month}/${year}.csv`;

    onClick(reportType, queryString, fileName)
  };
  const onClickStartEnd = (reportType, start, end, paymentType) => {
    if (!start || !end || !paymentType) {
      NotificationManager.error(
        `Please specify a valid report range and type`,
        "Error!",
        10000
      );
    } else {
      const queryString = getQueryString({start: start, end: end, paymentType: paymentType.id});
      const fileName = `${reportType}-${paymentType.name}-${readableDate(start)}-${readableDate(
        end
      )}.csv`;
      onClick(reportType, queryString, fileName)
    }
  }
  const onClickRange = reportType => {
    const queryString = getQueryString({start: startDate, end: endDate});
    const fileName = `${reportType}-${readableDate(startDate)}-${readableDate(
      endDate
    )}.csv`;

    if (!startDate || !endDate) {
      NotificationManager.error(
        `Please specify a valid report range`,
        "Error!",
        10000
      );
    } else {
      onClick(reportType, queryString, fileName)
    }
  };

  const onClick = (reportType, queryString, filename) => {
    setModalOpen(true);
    const method = "get";
    const url =
      endpoint +
      reportType +
      queryString;
    const conf = {
      method,
      url
    };
    axios(conf).then(({data}) => {
      setModalOpen(false);
      const downloadUrl = window.URL.createObjectURL(new Blob([data]));

      const link = document.createElement("a");

      link.href = downloadUrl;

      link.setAttribute("download", filename); //any other extension

      document.body.appendChild(link);

      link.click();

      link.remove();
    }).catch(() => {
      setReportErrored(true)
    });
  };
  if(!paymentTypesLoaded){
    return <LoadingSpinner/>
  }
  return (
    <div>
      <Box>
        <h1 className="title">Reports</h1>
        <Columns>
        </Columns>
      </Box>
      <Box>
        <Heading size={3}>Date Range Report</Heading>
        <Columns>
          {startDateInput}
          {endDateInput}
        </Columns>
        <Columns>
          <Columns.Column>
            <Button
              color="success"
              onClick={() => onClickRange("financedepreciationreport")}
            >
              Depreciation Report
            </Button>
          </Columns.Column>
          <Columns.Column>
            <Button
              color="success"
              onClick={() => onClickRange("financegrantreport")}
            >
              Grant Report
            </Button>
          </Columns.Column>
          <Columns.Column>
            <Button
              color="success"
              onClick={() => onClickRange("fleetvaluereport")}
            >
              Fleet Value Report
            </Button>
          </Columns.Column>
          <Columns.Column>
            <Button
              color="success"
              onClick={() => onClickRange("vehiclevatreport")}
            >
              Vehicle VAT Report
            </Button>
          </Columns.Column>
        </Columns>
      </Box>
      <Box>
        <Heading size={3}>Monthly Finance Report</Heading>
        <Columns>
          {monthInput}
          {yearInput}
          <Columns.Column></Columns.Column>
          <Columns.Column></Columns.Column>
          <Columns.Column></Columns.Column>
          <Columns.Column></Columns.Column>
          <Columns.Column></Columns.Column>
          <Columns.Column></Columns.Column>
        </Columns>
        <Columns>
          <Columns.Column>
            <Button
              color="success"
              onClick={() => onClickMonthly("monthlyfinancehpinterestreport")}
            >
              Fixed Rate Interest Report
            </Button>
          </Columns.Column>
          <Columns.Column>
            <Button
              color="success"
              onClick={() => onClickMonthly("financehireagreementreport")}
            >
              Hire Agreement Report
            </Button>
          </Columns.Column>
          <Columns.Column>
            <Button
              color="success"
              onClick={() => onClickMonthly("leaseagreementreport")}
            >
              Lease Agreement Report
            </Button>
          </Columns.Column>
          <Columns.Column>
            <Button
              color="success"
              onClick={() => onClickMonthly("financevariablerateinterestreport")}
            >
              Variable Rate Interest Report
            </Button>
          </Columns.Column>
          <Columns.Column>
            <Button
              color="success"
              onClick={() => onClickMonthly("financestockingplaninterestreport")}
            >
              Stocking Plan Interest Report
            </Button>
          </Columns.Column>
        </Columns>
      </Box>
      <Box>
        <Heading>Vehicle Details Reports</Heading>
        <Columns>
          <Columns.Column>
            <Button
              color="success"
              onClick={() => onClickNoParams("inspectionreport")}
            >
              Inspections Report
            </Button>
          </Columns.Column>
          <Columns.Column>
            <Button
              color="success"
              onClick={() => onClickNoParams("vehiclereport")}
            >
              Full Vehicle Details Report
            </Button>
          </Columns.Column>
        </Columns>
      </Box>
      <Box>
        <Heading>Parts Reports</Heading>
        <Columns>
          <Columns.Column>
            <Button
              color="success"
              onClick={() => onClickNoParams("partlookupreport")}
            >
              Part Stock Report
            </Button>
          </Columns.Column>
        </Columns>
      </Box>
      <Box>
        <Heading>Contacts Reports</Heading>
        <Columns>
          <Columns.Column>
            <Button
              color="success"
              onClick={() => onClickNoParams("contactreport")}
            >
              All Contacts Report
            </Button>
          </Columns.Column>
        </Columns>
      </Box>
      <Box>
        <Heading>Quote Reports</Heading>
        <Columns>
          <Columns.Column>
            <Button
              color="success"
              onClick={() => onClickNoParams("quotereport")}
            >
              Quote Cost Report
            </Button>
          </Columns.Column>
        </Columns>
      </Box>
      <Box>
        <Heading>Payment Type Reports</Heading>
        <Columns>
          {paymentTypeStartDateInput}
          {paymentTypeEndDateInput}
          <Columns.Column>
            <Form.Label>Payment Type</Form.Label>
            <PaymentTypePicker
              options={paymentTypes}
              editable={true}
              inlineEdit={false}
              blank={true}
              value={paymentType}
              onChange={e => setPaymentType(paymentTypeObjects[e.target.value])}
            />
          </Columns.Column>
        </Columns>
        <Columns>
          <Columns.Column>
             <Button
              color="success"
              onClick={() => onClickStartEnd("paymenttypereport", paymentTypeStartDate, paymentTypeEndDate, paymentType)}
            >
               Payment Type Report
            </Button>
          </Columns.Column>
        </Columns>
      </Box>
      <Modal
        closeOnBlur
        show={modalOpen}
        onClose={() => {
          setModalOpen(false);
          setReportErrored(false)
        }}
      >
        <Modal.Content>
          <Section style={{backgroundColor: "grey"}}>
          </Section>
          {!reportErrored &&
            <Box>
              <Heading>Preparing Report</Heading>
              <Spinner
                className="spinner-centre"
                size={120}
                spinnerColor={"#3273dc"}
                spinnerWidth={2}
              />
            </Box>
          }
          {reportErrored &&
            <Box>
              <Heading>An Error has occured!</Heading>
              <p>An error occured while preparing your report</p>
            </Box>
          }
        </Modal.Content>
      </Modal>
    </div>
  );
}
