import React, {useState} from "react";
import useEditableTableTextCell from "../../../hooks/useEditableTableTextCell";
import useEditableTableCostCell from "../../../hooks/useEditableTableCostCell";
import {Button} from "react-bulma-components";
import TransactionReportingCategoryPicker
    from "../Transactions/TransactionReportingCategories/TransactionReportingCategoryPicker";


export default function MovementTypeRow({type, dataFresh, handleChange, handleSave, index, onDelete, onReportingTypeChange, transactionReportingCategories}){
    const [editable, setEditable] = useState(false);
    const onChange = e => {
        handleChange(e,index)
    }
    const handleTransactionCategoryChange = e => {
        onReportingTypeChange(e, index)
    }
    const onSave = e => {
        handleSave(type);
        setEditable(false);
    }
    return (
        <tr>
            <td>
                {useEditableTableTextCell({
                    propName: "name",
                    value: type.name,
                    onSave,
                    onChange,
                    editable,
                    setEditable,
                    inlineEdit: true
                })}
            </td>
            <td>
                <TransactionReportingCategoryPicker
                    value={type.transaction_reporting_category}
                    editable={editable}
                    setEditable={setEditable}
                    inlineEdit={true}
                    disabled={!editable}
                    onSave={onSave}
                    onChange={handleTransactionCategoryChange}
                    options={transactionReportingCategories}
                />
            </td>
            <td>
                <Button
                    color={"danger"}
                    onClick={() => onDelete(type)}
                >
                    X
                </Button>
            </td>
        </tr>
    )


}