import React, {useState} from "react";
import {Box, Columns, Form, Heading, Pagination, Table} from "react-bulma-components";
import {useDispatch, useSelector} from "react-redux";
import {getDataFresh, getInvoicesData} from "../../../state/selectors";
import InvoiceRow from "./InvoiceRow";
import {useInvoicesData} from "../../../hooks/useInvoicesData";
import LoadingSpinner from "../../Utils/Spinner";
import {createSetDataFreshAction, createSetInvoicesDataAction} from "../../../actions/dataActions";
import axios from "axios";
import {NotificationManager} from "react-notifications";
import SearchBar from "../../Utils/SearchBar";
import useSelect from "../../../hooks/useSelect";
import FilterBlankHeading from "../../Invoicing/FilterBlankHeading";
import FilterHeading from "../../Invoicing/FilterHeading";



export default function InvoiceView(props){
    let [page, setPage] = useState(1);
    const [searchText , setSearchText] = useState();
    const [currentSearch, setCurrentSearch] = useState();
    const [filters, setFilters] = useState({});
    let params = {page: page};
    const viewOptions = [
        {name:"All", value: "all"},
        {name:"Approved", value:"approved"},
        {name:"Ready to Invoice", value:"ready"},
        {name:"Not Approved", value:"not_approved"}
    ]
    const [view, viewInput] = useSelect({
        label:"View",
        initialValue: "not_approved",
        options: viewOptions
    });
    if(view){
        params.view = view;
    }
    if(currentSearch){
        params.search = currentSearch;
    }
    for (let filter in filters) {
        if (filters[filter][0] && filters[filter][0][filter]) {
          params[filter] = filters[filter][0][filter];
        }
    }

    let dataFresh = useSelector(getDataFresh);
    let loaded = useInvoicesData(params, dataFresh);
    let data = useSelector(getInvoicesData);
    const dispatch = useDispatch();

    const headings = [
        {
            label: "Invoice ID",
            show: true
        },
        {
            label: "Customer",
            field: "customer",
            labelField: "customer__display_name",
            show: true
        },
        {
            label: "Type",
            show: true
        },
        {
            label: "Vatable Amount",
            show: true
        },
        {
            label: "Non Vatable Amount",
            show: true
        },
        {
            label: "VAT",
            show: true
        },
        {
            label: "Total Amount",
            show: true
        },
        {
            label: "Invoice Date",
            show: true
        },
        {
            label: "Date Paid",
            show: true
        },
        {
            label: "Invoice Number",
            show: true
        },
        {
            label: "Description",
            show: true
        },
        {
            label: "Comments",
            show: true
        },
        {
            label: "Approver",
            field: "approver",
            labelField: "approver__first_name",
            show: view === "approved"
        }
    ]



    if (!loaded){
        return <LoadingSpinner/>
    }

    const onChange = (e, index) => {
        let newInvoice = {...data.results[index], [e.target.name] : e.target.value}
        data.results[index] = newInvoice;
        dispatch(createSetInvoicesDataAction(newInvoice))
    };

    const onSave = (data) => {
        const conf = {
            url: props.endpoint+"invoices/"+data.id,
            method: "put",
            data: data
        }
        axios(conf).then(() => {
            dispatch(createSetDataFreshAction(dataFresh + 1));
        }).catch(()=> {
            NotificationManager.error("There was an error saving the movement")
        })
    };

    const onDelete = (data) => {
        const conf = {
            url: props.endpoint+"invoices/"+data.id,
            method: "delete"
        }
        axios(conf).then((res) => {
            dispatch(createSetDataFreshAction(dataFresh+1));
        }).catch(() => {
            NotificationManager.error("There was an error deleting that movement");
        })
    };
    const onApprove = (data) => {
        const conf = {
            url: props.endpoint+"invoiceapprove",
            method: "put",
            data: data
        };
        if(data.invoice_date && data.payment_date) {
            axios(conf).then(res => {
                dispatch(createSetDataFreshAction(dataFresh + 1))
            })
        }
        else {
            NotificationManager.warning("Invoice Date and Date Paid are required")
        }
    }

    const onReady = (data) => {
        const conf = {
            url: props.endpoint+"invoiceready",
            method: "put",
            data: data
        };
        axios(conf).then(res => {
            dispatch(createSetDataFreshAction(dataFresh + 1))
        }).catch(() => {
            NotificationManager.error("There was an error")
        })
    }

    const handleSearch = () => {
        setPage(1);
        setCurrentSearch(searchText);
    };

    const clear = () => {
        setSearchText("");
        setCurrentSearch("");
        setPage(1);
    };


    return (
        <div>
            <Box>
                <Columns>
                    <Columns.Column>
                        <Heading>Invoicing</Heading>
                    </Columns.Column>
                </Columns>
                <Columns>
                    <Columns.Column>
                        <Form.Label>Search</Form.Label>
                        <SearchBar
                            searchText={searchText}
                            setSearchText={setSearchText}
                            handleSearch={handleSearch}
                            clear={clear}
                        />
                    </Columns.Column>
                    <Columns.Column>
                        {viewInput}
                    </Columns.Column>
                </Columns>
            </Box>
            <Box>
                <Columns>
                 <Columns.Column>
                     <Table size={"fullwidth"}>
                         <thead>
                            <tr>
                                {headings.map(heading => {
                                    if (!heading.field && heading.show) {
                                      return <th key={heading.label}>{heading.label}</th>;
                                    }
                                    if (heading.blank) {
                                      return (
                                        <FilterBlankHeading
                                          key={heading.label}
                                          heading={heading}
                                          setFilters={f => {
                                            setPage(1);
                                            setFilters(f);
                                          }}
                                          filters={filters}
                                        />
                                      );
                                    }
                                    if(heading.show) {
                                        return (
                                            <FilterHeading
                                                key={heading.label}
                                                heading={heading}
                                                setFilters={f => {
                                                    setPage(1);
                                                    setFilters(f);
                                                }}
                                                filters={filters}
                                                options={data.filter_list[heading.field]}
                                            />
                                        );
                                    }
                                  })}
                            </tr>
                         </thead>
                         <tbody>
                            {data.results.map((invoice, index) => (
                                <InvoiceRow
                                    index={index}
                                    invoice={invoice}
                                    onChange={onChange}
                                    onSave={onSave}
                                    onDelete={onDelete}
                                    onApprove={onApprove}
                                    showApproved={view === "approved"}
                                    onReady={onReady}
                                />
                            ))}
                         </tbody>
                     </Table>
                     <Pagination
                        showFirstLast={true}
                        onChange={setPage}
                        current={page}
                        total={Math.ceil(data.count / 25)}
                     ></Pagination>
                 </Columns.Column>
                </Columns>
            </Box>
        </div>
    )


}