import React, {useState} from "react";
import {Box, Button, Columns, Form} from "react-bulma-components";
import Collapsible from "react-collapsible";


export default function NewDriver({onSave}){
    const [driver, setDriver] = useState({
        name: "",
    })
    const onChange = e => {
        let newDriver = {...driver, [e.target.name]: e.target.value}
        setDriver(newDriver);
    }
    return (
        <Box>
            <Collapsible
                className={"title"}
                trigger={"Add Driver"}
                triggerOpenedClassName={"title"}
            >
                <Columns>
                    <Columns.Column>
                        <Form.Field>
                            <Form.Label>Name</Form.Label>
                            <Form.Input
                                name={"name"}
                                type={"text"}
                                onChange={onChange}
                                value={driver.name}
                            />
                        </Form.Field>
                    </Columns.Column>
                    <Columns.Column/>
                    <Columns.Column/>
                    <Columns.Column/>
                </Columns>
                <Columns>
                    <Columns.Column>
                        <Button
                            color={"success"}
                            onClick={() => onSave(driver)}
                            fullwidth
                        >
                            Create
                        </Button>
                    </Columns.Column>
                </Columns>
            </Collapsible>
        </Box>
    )


}