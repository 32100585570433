import React from "react";
import shortid from "shortid";
import {Notification, Table} from "react-bulma-components";
import { Box } from "react-bulma-components";
import Part from "./Part";
import { Button } from "react-bulma-components";
import {getPartCustomerCost, getPartCustomerEstimatedCost, getWorkItemCustomerCost} from "../../utils";

import { Columns } from "react-bulma-components";
import { Form } from "react-bulma-components";
import { Icon } from "react-bulma-components";
import { Link } from "react-router-dom";
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import {faPoundSign} from "@fortawesome/free-solid-svg-icons";
import WorkTypePicker from "./WorkTypePicker";



function WorkSection(props) {
  const handleChange = e => {
    const newWorkItem = getNewWorkItem(e);
    props.update_work(newWorkItem, props.index);
  };

  const handleTickBox = e => {
    const toUpdate = {
      target: {
        name: "approval_received",
        value: !props.workItem.approval_received
      }
    };
    handleChange(toUpdate);
  }


  const handleTypeSelect = e => {
    let value = e.target.value === "BLANK" ? null : props.workTypeObjects[e.target.value];
    const toUpdate = {
      target: {
        name: "work_type",
        value: value
      }
    };
    handleChange(toUpdate);
  }

  const getNewWorkItem = e => {
    let chargeable =
      e.target.name === "chargeable"
        ? e.target.value
        : props.workItem.chargeable;
    let customer = chargeable ? props.hireCustomer : props.customer;

    let newWorkItem = { ...props.workItem, [e.target.name]: e.target.value };
    if (
      (e.target.name === "labour_hours" ||
        e.target.name === "chargeable" ||
        e.target.name === "cost_override") &&
      !newWorkItem.cost_override && !newWorkItem.movement
    ) {
      // If the work item is linked to a movement, do not recalculate its cost
      newWorkItem = getWorkItemCustomerCost(props.vehicle, props.supplier, newWorkItem, customer, props.lcv_job);
    }
    return newWorkItem;
  };

  const onCheck = propName => {
    const newWorkItem = getNewWorkItem({
      target: {
        name: propName,
        value: !props.workItem[propName]
      }
    });
    newWorkItem.parts = props.workItem.parts.map(part => {
      return {
        ...part,
        customer_cost: getPartCustomerCost(
          part,
          newWorkItem.chargeable ? props.hireCustomer : props.customer,
          props.appDefaults
        ),
        estimated_customer_cost: getPartCustomerEstimatedCost(
          part,
          newWorkItem.chargeable ? props.hireCustomer : props.customer,
          props.appDefaults
        )
      };
    });
    props.update_work(newWorkItem, props.index);
  };

  const addPart = e => {
    props.update_work(
      {
        ...props.workItem,
        parts: props.workItem.parts.concat({
          key: shortid.generate(),
          name: "",
          cost: 0,
          supplier: {},
          date_ordered: "",
          part_invoice_number: "",
          part_number: "",
          from_stock: false,
          backorder_expected_date: "",
          to_be_ordered: true,
          cost_override: false,
          customer_cost: 0,
          quote: props.workItem.quote
        })
      },
      props.index
    );
  };

  const updateParts = (part, index) => {
    let newParts = [...props.workItem.parts];
    newParts.splice(index, 1, part);
    const newWorkItem = {
      ...props.workItem,
      parts: newParts
    };
    props.update_work(newWorkItem, props.index);
  };

  const removePart = index => {
    let newParts = [...props.workItem.parts];
    newParts.splice(index, 1);
    const newWorkItem = {
      ...props.workItem,
      parts: newParts
    };
    props.update_work(newWorkItem, props.index);
  };

  const allMatches = props.workItem.estimate_matches_job;

  const borderClass = props.workItem.complete ? "box-border-green" : ""

  return (
    <Box className={borderClass}>
      {props.quote && (
        <Columns>
          <Columns.Column>
            <p>This item is associated with a Quote!</p>
          </Columns.Column>
          <Columns.Column>
            {allMatches ? (
              ""
            ) : (
              <p>This work item doesn't match the quote!</p>
            )}
          </Columns.Column>
        </Columns>
      )}
      <Columns>
        <Columns.Column>
          <Form.Field>
            <Form.Control>
              <Form.Label>Description</Form.Label>
              <Form.Input
                data-testid="description"
                type="text"
                name="description"
                onChange={handleChange}
                value={props.workItem.description}
                disabled={props.workItem.invoice_approved}
                required
              />
            </Form.Control>
          </Form.Field>
        </Columns.Column>
      </Columns>
      <Columns>
        <Columns.Column>
          <Form.Field>
            <Form.Control>
              <Form.Label>Charge Customer 2</Form.Label>
              <Form.Checkbox
                type="checkbox"
                name="chargeable"
                onChange={() => onCheck("chargeable")}
                checked={props.workItem.chargeable}
                disabled={!props.hireCustomer || props.workItem.invoice_approved}
              />
            </Form.Control>
          </Form.Field>
        </Columns.Column>
        {props.workItem.quote && (
          <Columns.Column>
            <Form.Field>
              <Form.Control>
                <Form.Label>Est. Labour Hours</Form.Label>
                <Form.Input
                  data-testid="estimated_labour_hours"
                  className="input"
                  type="number"
                  name="estimated_labour_hours"
                  readOnly
                  disabled
                  value={String(props.workItem.estimated_labour_hours)}
                />
              </Form.Control>
            </Form.Field>
          </Columns.Column>
        )}
        {props.workItem.quote && (
          <Columns.Column>
            <Form.Field>
              <Form.Label>Est. Labour Cost</Form.Label>
              <Form.Control>
                <Form.Input
                  className="input"
                  type="number"
                  name="estimated_labour_cost"
                  readOnly
                  disabled
                  value={String(props.workItem.estimated_labour_cost)}
                />
                <Icon align={"left"} className={"small-icon"}>
                  <FontAwesomeIcon icon={faPoundSign}/>
                </Icon>
              </Form.Control>
            </Form.Field>
          </Columns.Column>
        )}
        {props.workItem.quote && (
          <Columns.Column>
            <Form.Field>
              <Form.Label>Est. Customer Charge</Form.Label>
              <Form.Control>
                <Form.Input
                  className="input"
                  type="number"
                  name="estimated_customer_labour_cost"
                  readOnly
                  disabled
                  value={String(props.workItem.estimated_customer_labour_cost)}
                />
                <Icon align={"left"} className={"small-icon"}>
            <FontAwesomeIcon icon={faPoundSign}/>
          </Icon>
              </Form.Control>
            </Form.Field>
          </Columns.Column>
        )}
        <Columns.Column>
          <Form.Field>
            <Form.Control>
              <Form.Label>Labour Hours</Form.Label>
              <Form.Input
                data-testid="labour_hours"
                className="input"
                type="number"
                name="labour_hours"
                onChange={handleChange}
                value={String(props.workItem.labour_hours)}
                disabled={props.workItem.invoice_approved || !!props.workItem.movement}
              />
            </Form.Control>
          </Form.Field>
        </Columns.Column>
        <Columns.Column>
          <Form.Field>
            <Form.Label>Labour Cost</Form.Label>
            <Form.Control>
              <Form.Input
                className="input"
                type="number"
                name="labour_cost"
                onChange={handleChange}
                value={String(props.workItem.labour_cost)}
                disabled={!props.workItem.cost_override || props.workItem.invoice_approved}
              />
              <Icon align={"left"} className={"small-icon"}>
            <FontAwesomeIcon icon={faPoundSign}/>
          </Icon>
            </Form.Control>
          </Form.Field>
        </Columns.Column>
        <Columns.Column>
          <Form.Field>
            <Form.Label>Customer Charge</Form.Label>
            <Form.Control>
              <Form.Input
                className="input"
                type="number"
                name="customer_labour_cost"
                onChange={handleChange}
                value={String(props.workItem.customer_labour_cost)}
                disabled={!props.workItem.cost_override || props.workItem.invoice_approved}
              />
              <Icon align={"left"} className={"small-icon"}>
            <FontAwesomeIcon icon={faPoundSign}/>
          </Icon>
            </Form.Control>
          </Form.Field>
        </Columns.Column>
        <Columns.Column>
          <Form.Field>
            <Form.Control>
              <Form.Label>Override Cost</Form.Label>
              <Form.Checkbox
                type="checkbox"
                name="cost_override"
                onChange={() => onCheck("cost_override")}
                disabled={props.workItem.invoice_approved || !!props.workItem.movement}
                checked={props.workItem.cost_override}
              />
            </Form.Control>
          </Form.Field>
        </Columns.Column>
        <Columns.Column>
          <Form.Field>
            <Form.Control>
              <Form.Label>Work Complete</Form.Label>
              <Form.Checkbox
                type="checkbox"
                name="work_complete"
                onChange={() => onCheck("complete")}
                disabled={props.workItem.invoice_approved || !!props.workItem.movement}
                checked={props.workItem.complete}
              />
            </Form.Control>
          </Form.Field>
        </Columns.Column>
      </Columns>
      <Columns>
        <Columns.Column>
          <WorkTypePicker
            options={props.workTypes}
            work={props.workItem}
            onSelect={handleTypeSelect}
            inlineEdit={false}
            disabled={props.workItem.invoice_approved || !!props.workItem.movement}
          />
        </Columns.Column>
        {props.workItem.work_type && props.workItem.work_type.approval_needed &&
          <Columns.Column>
            <Form.Field>
              <Form.Label>Approval Received</Form.Label>
              <Form.Checkbox
                name={"approval_received"}
                onChange={handleTickBox}
                checked={props.workItem.approval_received}
                disabled={props.workItem.invoice_approved || props.workItem.customer_labour_cost == 0}
              />
            </Form.Field>
          </Columns.Column>
        }
      </Columns>
      <Table size={"fullwidth"}>
        <thead>
          <tr>
            <td>Name</td>
            {props.quote && <td>Est. Cost</td>}
            {props.quote && <td>Est. Charge</td>}
            <td>Supplier</td>
            <td>Date Ordered</td>
            <td>Part Number</td>
            <td>Invoice No</td>
            <td>Actual Cost</td>
            <td>Actual Charge</td>
            <td>Comments</td>
            <td>Override Cost</td>
            <td>Stock Accrual</td>
            <td>Backorder Date</td>
            <td>To Be Ordered</td>
            <td>Remove</td>
          </tr>
        </thead>
        <tbody data-testid="parts">
          {props.workItem.parts.map((part, index) => (
            <Part
              key={part.id || part.key}
              index={index}
              part={part}
              job={props.job}
              quote={props.quote}
              updateParts={updateParts}
              removePart={removePart}
              customer={
                props.workItem.chargeable ? props.hireCustomer : props.customer
              }
              appDefaults={props.appDefaults}
              onSearchPartClick={(part, partIndex) =>
                props.onSearchPartClick(
                  part,
                  partIndex,
                  props.index,
                  props.customer
                )
              }
              invoiced={props.workItem.invoice_approved}
            />
          ))}
        </tbody>
      </Table>
      <Button
        color="primary"
        onClick={addPart}
        data-testid="add-part"
        disabled={
          !!(props.workItem.quote && !props.workItem.added_after_quote) ||
          !!props.workItem.invoice_approved || !!props.workItem.movement
        }
      >
        Add Part
      </Button>
      <Button
        color="danger"
        data-testid="remove-work"
        className="is-pulled-right remove-work"
        onClick={() => props.remove_work_item(props.index)}
        disabled={!!props.workItem.invoice_approved || !!props.workItem.movement}
      >
        Remove Work
      </Button>
    </Box>
  );
}

export default WorkSection;
