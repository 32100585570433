import React, {useRef, useState} from "react";
import {Box, Section} from "react-bulma-components";
import { Form } from "react-bulma-components";
import { Button } from "react-bulma-components";
import { Pagination } from "react-bulma-components";
import { Tabs } from "react-bulma-components";
import { Columns } from "react-bulma-components";
import {formatDate, readableDate} from "../../utils";
import {
  getContactsData,
  getDataFresh,
  getDefectCountData,
  getDepartmentsData, getEnquiryCountData,
  getFittersData,
  getJobsData, getJobStatusCountData
} from "../../state/selectors";
import { useDispatch, useSelector } from "react-redux";
import Spinner from "react-spinner-material";
import { useJobsData } from "../../hooks/useJobsData";
import Figures from "./Figures";
import useEditableTableDateCell from "../../hooks/useEditableTableDateCell";
import useSafeSave from "../../hooks/useSafeSave";
import axios from "axios";
import DepartmentPicker from "./DepartmentPicker";
import {
  createSetDataFreshAction,
  createSetJobsDataAction,
  createSetPartsDataAction
} from "../../actions/dataActions";
import JobRow from "./JobRow";
import PrintJob from "./PrintJob";
import FilterBlankHeading from "../Invoicing/FilterBlankHeading";
import FilterHeading from "../Invoicing/FilterHeading";
import ReactToPrint from "react-to-print";
import PrintListWash from "./PrintListWash";
import {useWorkshopsData} from "../../hooks/useWorkshopsData";
import {useDepartmentsData} from "../../hooks/useDepartmentsData";
import {useFittersData} from "../../hooks/useFittersData";
import PrintJobList from "./PrintJobList";
import KeyFilterDropDown from "./KeyFilterDropDown";
import {useDefectCountData} from "../../hooks/useDefectCountData";
import {usePartEnquiryCountData} from "../../hooks/usePartEnquiryCountData";
import SearchBar from "../Utils/SearchBar";
import {useJobStatusCountData} from "../../hooks/useJobStatusCountData";

function Job(props) {
  const [page, setPage] = useState(1);
  const [date, setDate] = useState();
  const [activeView, setView] = useState("started");
  const [activeTab, setTab] = useState("all");
  const [searchText, setSearchText] = useState();
  const [currentSearch, setCurrentSearch] = useState();
  const [selectedDepartment, setSelectedDepartment] = useState();
  const [keyFilter, setKeyFilter] = useState();
  const [searchFresh, setSearchFresh] = useState(1);
  const dispatch = useDispatch();
  let dataFresh = useSelector(getDataFresh);
  const componentRef = useRef();
  const printJobListRef = useRef();
  let params = { page, view: activeView, date: date};
  const [filters, setFilters] = useState({});
  const headings = [
    {
        label: "Job no.",
    },
    {
        label: "Registration",
        field: "vehicle",
        labelField: "vehicle__registration"
    },
    {
        label: "Description"
    },
    {
        label: "Comments"
    },
    {
        label: "Customer",
        field: "customer",
        labelField: "customer__display_name"
    },
    {
        label: "Customer2",
        field: "customer2",
        labelField: "customer2__display_name"
    },
    {
      label: "Fitter",
      field: "fitter",
      labelField: "fitter__name"
    },
    {
        label: "Make"
    },
    {
        label: "Model"
    },
    {
        label: "Job Status",
        field: "status",
        labelField: "status"
    },
    {
        label: "Workshop Date",
        field: "workshop_date",
        labelField: "workshop_date",
        date : true
    },
    {
        label: "Book in Date",
        field: "book_in_date",
        labelField: "book_in_date",
        date: true,
        noBlank: true
    },
    {
        label: "Workshop",
        field: "workshop",
        labelField: "workshop__display_name"
    }
  ];

  for (let filter in filters) {
    if (filters[filter][0] && filters[filter][0][filter]) {
      params[filter] = filters[filter][0][filter];
    }
  }

  if (activeTab) {
    params.ws = activeTab;
  }

  if (currentSearch) {
    params.search = currentSearch;
  }
  if (searchFresh){
    params.searchFresh = searchFresh;
  }
  if (selectedDepartment){
    params.department = selectedDepartment;
  }
  if (keyFilter){
    params.key = keyFilter;
  }
  let countParams = {};
  if(activeTab){
    countParams.ws = activeTab;
  }
  const complete = useJobsData(params, dataFresh);
  const departmentsComplete = useDepartmentsData({}, dataFresh);
  const fittersComplete = useFittersData({}, dataFresh);
  const departments = useSelector(getDepartmentsData);
  const fitters = useSelector(getFittersData);
  const data = useSelector(getJobsData);
  const completeTabs = useWorkshopsData({}, dataFresh);
  const tabs = useSelector(getContactsData);

  const defectCountComplete = useDefectCountData({});
  const enquiryCountComplete = usePartEnquiryCountData({});
  const jobStatusCountComplete = useJobStatusCountData(countParams, dataFresh);

  let defectCount = useSelector(getDefectCountData);
  let enquiryCount = useSelector(getEnquiryCountData);
  let jobStatusCount = useSelector(getJobStatusCountData);

  const handleSearch = () => {
    setPage(1);
    setCurrentSearch(searchText);
    setSearchFresh(searchFresh+1);
  };

  const setNewView = (view) => {
    setPage(1)
    setView(view)
    setDate()
  };

  const setNewTab = (tab) => {
    setPage(1)
    setTab(tab)
  };

  const clear = () => {
    setSearchText("");
    setCurrentSearch("");
    setPage(1);
  };
  const onDepartmentChange = (e) => {
    setSelectedDepartment(e.target.value);
  }
  const onJobUpdate = (index, newJob) => {
    let newState = { ...data };
    newState.results[index] = newJob;
    dispatch(createSetJobsDataAction(newState));
  };

  const onKeyFilterChange = (e) =>{
    setKeyFilter(e.target.value);
  }
  if (!complete || !completeTabs || !departmentsComplete || !fittersComplete || !defectCountComplete || !enquiryCountComplete) {
    return (
      <Box>
        <Spinner
          className="spinner-centre"
          size={120}
          spinnerColor={"#3273dc"}
          spinnerWidth={2}
        />
      </Box>
    );
  }

  document.title = "Jobs";

  return (
    <div>
      <Figures department={selectedDepartment}/>
      <Box>
        <h1 className="title">Jobs</h1>
        <Columns>
          <Columns.Column>
            <Form.Label>Search for a Job</Form.Label>
            <SearchBar
              handleSearch={handleSearch}
              setSearchText={setSearchText}
              searchText={searchText}
              clear={clear}
            />
          </Columns.Column>
          <Columns.Column>
           <DepartmentPicker
            onDepartmentChange={onDepartmentChange}
            selectedDepartment={selectedDepartment}
            departments={departments}
           />
          </Columns.Column>
          <Columns.Column>
            <KeyFilterDropDown
              selectedFilter={keyFilter}
              onChange={onKeyFilterChange}
            />
          </Columns.Column>
          <Columns.Column>
            <Form.Field>
              <Form.Control>
                <Button
                  onClick={() => props.history.push("/editjob")}
                  color="success"
                  className="is-pulled-right"
                >
                  New Job +
                </Button>
              </Form.Control>
            </Form.Field>
          </Columns.Column>
          <Columns.Column>
            <Form.Field>
              <Form.Control>
                <Button
                  color={"success"}
                  className={"is-pulled-right"}
                  onClick={() => props.history.push("/partenquiry")}
                >
                  New Part Enquiry +
                </Button>
              </Form.Control>
            </Form.Field>
          </Columns.Column>
          <Columns.Column>
            <Form.Field>
              <Form.Control>
                <Button
                  onClick={() => props.history.push("/defect")}
                  color="success"
                  className="is-pulled-right"
                >
                  New Defect +
                </Button>
              </Form.Control>
            </Form.Field>
          </Columns.Column>
        </Columns>
        <Tabs type="boxed" fullwidth align="centered">
          <Tabs.Tab active={activeTab === "all"} onClick={() => setNewTab("all")}>
            ALL
          </Tabs.Tab>
          <Tabs.Tab
            active={activeTab === "quotes"}
            onClick={() => setNewTab("quotes")}
          >
            QUOTE JOBS
          </Tabs.Tab>
          <Tabs.Tab active={activeTab === "external"} onClick={() => setNewTab("external")}>
            EXTERNAL
          </Tabs.Tab>
          {tabs.results.map((tab) => (
              <Tabs.Tab active={activeTab === tab.display_name} onClick={() => setNewTab(tab.display_name)}>
                {tab.display_name}
              </Tabs.Tab>
          ))}
        </Tabs>
        <Tabs type="toggle" align="centered">
          <Tabs.Tab
            active={activeView === "part_enquiry"}
            onClick={() => setNewView("part_enquiry")}
            className={enquiryCount > 0 ? "table-highlight-red": ""}
            textColor={enquiryCount > 0 ? "white": ""}
          >
            Part Enquiries {enquiryCountComplete ? "("+enquiryCount+")" : ""}
          </Tabs.Tab>
          <Tabs.Tab
            active={activeView === "user_defect"}
            onClick={() => setNewView("user_defect")}
          >
            My Defects
          </Tabs.Tab>
          <Tabs.Tab
            active={activeView === "defect"}
            onClick={() => setNewView("defect")}
            className={ defectCount > 0 ? "table-highlight-red": ""}
            textColor={defectCount > 0 ? "white" : ""}
          >
            Defects {defectCountComplete ? "("+defectCount+")" : ""}
          </Tabs.Tab>
          <Tabs.Tab
            active={activeView === "yesterday"}
            onClick={() => setNewView("yesterday")}
          >
            Yesterday {jobStatusCountComplete ? "("+jobStatusCount.yesterday+")" : ""}
          </Tabs.Tab>
          <Tabs.Tab
            active={activeView === "today"}
            onClick={() => setNewView("today")}
          >
            Today {jobStatusCountComplete ? "("+jobStatusCount.today+")" : ""}
          </Tabs.Tab>
          <Tabs.Tab
            active={activeView === "started"}
            onClick={() => setNewView("started")}
          >
            Started {jobStatusCountComplete ? "("+jobStatusCount.started+")" : ""}
          </Tabs.Tab>
          <Tabs.Tab
            active={activeView === "upcoming"}
            onClick={() => setNewView("upcoming")}
          >
            Upcoming {jobStatusCountComplete ? "("+jobStatusCount.upcoming+")" : ""}
          </Tabs.Tab>
          <Tabs.Tab
            active={activeView === "upcoming_not_booked"}
            onClick={() => setNewView("upcoming_not_booked")}
          >
            Upcoming Not Booked {jobStatusCountComplete ? "("+jobStatusCount.upcoming_not_booked+")" : ""}
          </Tabs.Tab>
          <Tabs.Tab
            active={activeView === "returned"}
            onClick={() => setNewView("returned")}
          >
            Returned From Workshop {jobStatusCountComplete ? "("+jobStatusCount.returned+")" : ""}
          </Tabs.Tab>
          <Tabs.Tab
              active={activeView === "wash_needed"}
              onClick={() => setNewView("wash_needed")}
          >
            Wash Needed {jobStatusCountComplete ? "("+jobStatusCount.wash+")" : ""}
          </Tabs.Tab>
          <Tabs.Tab
            active={activeView === "ready_for_collection"}
            onClick={() => setNewView("ready_for_collection")}
          >
            Ready For Collection {jobStatusCountComplete ? "("+jobStatusCount.collection+")" : ""}
          </Tabs.Tab>
          <Tabs.Tab
            active={activeView === "approved"}
            onClick={() => setNewView("approved")}
          >
            Approved
          </Tabs.Tab>
          <Tabs.Tab
            active={activeView === "all"}
            onClick={() => setNewView("all")}
          >
            All
          </Tabs.Tab>
        </Tabs>
        {(activeView === "wash_needed") &&
            <Columns.Column pull={"right"}>
              <Form.Label>Wash List Date</Form.Label>
              <Form.Field className="contact-section has-addons">
                <Form.Control>
                  <Form.Input
                    type="date"
                    name="day"
                    value={date}
                    onChange={e => (setDate(e.target.value))}
                  />
                </Form.Control>
              </Form.Field>
            </Columns.Column>
        }
        <table className="table is-hoverable is-fullwidth is-striped">
          <thead>
            <tr className="small-row-black">
              {headings.map(heading => {
                if (!heading.field) {
                  return <th key={heading.label}>{heading.label}</th>;
                }
                if (heading.blank) {
                  return (
                    <FilterBlankHeading
                      key={heading.label}
                      heading={heading}
                      setFilters={f => {
                        setPage(1);
                        setFilters(f);
                      }}
                      filters={filters}
                    />
                  );
                }
                return (
                  <FilterHeading
                    key={heading.label}
                    heading={heading}
                    setFilters={f => {
                      setPage(1);
                      setFilters(f);
                    }}
                    filters={filters}
                    options={data.filter_list[heading.field]}
                  />
                );
              })}
              {(activeView === 'finished' || activeView === 'all') &&
                <th>Approver</th>
              }
              {(activeView === 'wash_needed') &&
                <th>Wash Completed</th>
              }
              {activeView === "wash_needed" &&
                <td onClick={e => e.stopPropagation()}>
                  <ReactToPrint
                    trigger={() => (
                      <Button color="warning">
                        Print Wash List
                      </Button>
                    )}
                    content={() => componentRef.current}
                    bodyClass="print-landscape"
                    pageStyle=""
                  />
                </td>
              }
            </tr>
          </thead>
          <tbody>
            {data.results.map((value, index) => (
              <JobRow
                key={index}
                job={value}
                onJobUpdate={onJobUpdate}
                index={index}
                endpoint={props.endpoint}
                showApprover={activeView === 'finished' || activeView === 'all'}
                fitters={fitters}
                showWash={activeView === "wash_needed"}
                showStatusChange={activeView === "ready_for_collection"}
              />
            ))}
            <tr>
              <td></td>
              <td></td>
              <td></td>
              <td></td>
              <td></td>
              <td></td>
              <td></td>
              <td></td>
              <td></td>
              <td></td>
              <td></td>
              <td></td>
              <td></td>
              {(activeView === 'finished' || activeView === 'all') &&
                  <td></td>
              }
              <td onClick={e => e.stopPropagation()}>
                <ReactToPrint
                    trigger={() => (
                        <Button
                            color="warning"
                            disabled={activeView==="defect" || activeView==="user_defect" || activeView==="part_enquiry"}
                        >
                          Print Job List
                        </Button>
                    )}
                    content={() => printJobListRef.current}
                    bodyClass="print-landscape"
                    pageStyle=""
                />
              </td>
            </tr>
          </tbody>
        </table>
        <div style={{display: "none"}}>
          <div>
            {activeView === 'wash_needed' &&
                <PrintListWash data={data} ref={componentRef} testId="PrintWashList"/>
            }
            <PrintJobList jobs={data} ref={printJobListRef} testId={"PrintJobsList"}/>
          </div>
        </div>
        <Pagination
            showFirstLast={true}
            onChange={setPage}
            current={page}
            total={Math.ceil(data.count / 25)}
        ></Pagination>
      </Box>
    </div>
  );
}

export default Job;
