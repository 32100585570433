import React from "react";
import {Box, Heading, Table} from "react-bulma-components";
import { useSelector} from "react-redux";
import {getContactsData, getDataFresh} from "../../../state/selectors";
import NewJobWorkshopTab from "./NewJobWorkshopTab";
import {useWorkshopsData} from "../../../hooks/useWorkshopsData";
import Spinner from "react-spinner-material";
import WorkshopRow from "./WorkshopRow";



function JobWorkshopView(props){
    let params = {}
    let dataFresh = useSelector(getDataFresh);

    let loading = useWorkshopsData(params, dataFresh);
    let workshops = useSelector(getContactsData);

    if (!loading) {
        return (
        <Box>
            <Spinner
                className="spinner-centre"
                size={120}
                spinnerColor={"#3273dc"}
                spinnerWidth={2}
            />
        </Box>
        );
    }

    return(
        <div>
            <br/>
            <NewJobWorkshopTab
                dataFresh={dataFresh}
            />
            <br/>
            <Box>
                <Heading>Job Tab Workshops</Heading>
                <Table>
                    <thead>
                        <tr>
                            <th>ID</th>
                            <th>Name</th>
                            <th></th>
                        </tr>
                    </thead>
                    {workshops.results.map((workshop) => (
                        <WorkshopRow workshop={workshop} dataFresh={dataFresh} />
                    ))}
                </Table>
            </Box>
        </div>
    )



}
export default JobWorkshopView;