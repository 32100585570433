import React, {useState} from "react";
import {Box, Columns, Table} from "react-bulma-components";
import DriverRow from "./DriverRow";
import {useDispatch, useSelector} from "react-redux";
import {getDataFresh, getDriversData} from "../../../state/selectors";
import LoadingSpinner from "../../Utils/Spinner";
import Collapsible from "react-collapsible";
import NewDriver from "./NewDriver";
import axios from "axios";
import {createSetDataFreshAction, createSetDriversDataAction} from "../../../actions/dataActions";
import {NotificationManager} from "react-notifications";
import {useDriversData} from "../../../hooks/useDriversData";


export default function DriverView(props){
    const [page, setPage] = useState(1);
    const dispatch = useDispatch();
    let dataFresh = useSelector(getDataFresh);
    let loaded = useDriversData({page}, dataFresh);
    let data = useSelector(getDriversData);


    const onSave = (item) => {
        let url = item.id ? props.endpoint+"drivers/"+item.id : props.endpoint+"drivers";
        const conf = {
            url: url,
            method: item.id ? "put": "post",
            data:item
        }
        axios(conf).then(() => {
            dispatch(createSetDataFreshAction(dataFresh + 1));
        }).catch(err => {
            NotificationManager.error("There was an error saving this driver");
        })
    }
    const onDelete = item => {
        const conf = {
            url: props.endpoint+"drivers/"+item.id,
            method: "delete"
        }
        axios(conf).then(() => {
            dispatch(createSetDataFreshAction(dataFresh + 1));
        }).catch(err => {
            NotificationManager.error("There was an error deleting this driver");
        })
    }
    const onChange = (e,index) => {
        let newDrivers = [...data.results];
        newDrivers[index][e.target.name] = e.target.value;
        dispatch(createSetDriversDataAction(newDrivers));
    }

    if(!loaded){
        return (<LoadingSpinner />);
    }

    return (
        <div>
            <br/>
            <NewDriver onSave={onSave}/>
            <Box>
                <Collapsible
                    trigger={"Drivers"}
                    triggerOpenedClassName={"title"}
                    className={"title"}
                >
                    <Columns>
                        <Columns.Column>
                            <Table>
                                <thead>
                                    <tr>
                                        <th>Name</th>
                                        <th></th>
                                    </tr>
                                </thead>
                                <tbody>
                                {data.results.map((driver, index) => (
                                    <DriverRow
                                        driver={driver}
                                        handleSave={onSave}
                                        index={index}
                                        handleChange={onChange}
                                        onDelete={onDelete}
                                    />
                                ))}
                                </tbody>
                            </Table>
                        </Columns.Column>
                    </Columns>
                </Collapsible>
            </Box>
        </div>
    )

}