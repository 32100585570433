import React, {useEffect, useRef, useState} from "react";
import shortid from "shortid";
import {Table} from "react-bulma-components";
import {Box} from "react-bulma-components";
import {Button} from "react-bulma-components";
import Collapsible from "react-collapsible";
import {getPartCustomerCost, readableDate} from "../../utils";
import {asyncContainer, Typeahead} from "react-bootstrap-typeahead";

import {Columns} from "react-bulma-components";
import {Form} from "react-bulma-components";
import {Icon} from "react-bulma-components";
import {Modal} from "react-bulma-components";
import {Section} from "react-bulma-components";
import {Heading} from "react-bulma-components";
import {getPaymentSchedule} from "../../financeUtils";
import StockingPlanSection from "./StockingPlanSection";
import {faPoundSign} from "@fortawesome/free-solid-svg-icons";
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import axios from "axios";
import SaleInfo from "./SaleInfo";
import HireAgreementSection from "./HireAgreementSection";
import NegativeFinanceSection from "./NegativeFinanceSection";
import ReactToPrint from "react-to-print";
import PrintSalesInvoice from "./PrintSalesInvoice";
import PaymentModal from "./PaymentModal";
import useInput from "../../hooks/useInput";

const AsyncTypeahead = asyncContainer(Typeahead);
function FinanceSection({
                          updateFinanceAgreements,
                          financeAgreement,
                          vehicle,
                          index,
                          defaultDepreciation,
                          removeFinanceAgreement,
                          serviceData,
                          endpoint
                        }) {
  const [capturedEvent, setCapturedEvent] = useState();
  const [modalOpen, setModalOpen] = useState(false);
  const paymentSchedule = getPaymentSchedule(financeAgreement);
  const [modificationChecked, setModificationChecked] = useState(financeAgreement.modification);
  const [isLoading, setLoading] = useState(false);
  const [searchData, setSearchData] = useState([]);
  const [addonsPurchasePrice, setAddonsPurchasePrice] = useState((financeAgreement.purchase_price - financeAgreement.vehicle_purchase_price).toFixed(2));
  const [, setTypeahead] = useState();
  const [paymentModal, setPaymentModal] = useState(false);
  const [stateUpdate, setStateUpdate] = useState(0);
  const printSaleInvoice = useRef();





  const handleSupplierSearch = e => {
    setLoading(true);
    axios.get(`/api/contacts?contact_type=SP&search=${e}`).then(resp => {
      setSearchData(resp.data.results);
      setLoading(false);
    });
  };
  const handleSupplierSelect = supplier => {
    onChange({target: {name: 'supplier', value: supplier[0]}})
  };

  const onChange = e => {
    const newFinanceAgreement = getNewFinanceAgreement(e);
    if(e.target.name === "sold_date"){
      setPaymentModal(true);
    }
    setStateUpdate(stateUpdate+1);
    updateFinanceAgreements(newFinanceAgreement, index);
  };
  useEffect(() => {
    if(!financeAgreement.pro_forma_date){
      let today = new Date();
      let todayString = today.getFullYear()+"-"+today.getMonth()+"-"+today.getDate();
      let toUpdate = {
        target: {
          name:"pro_forma_date",
          value: todayString
        }
      };
      onChange(toUpdate);
    }
  }, [financeAgreement]);
  const onConfirmChange = () => {
    let finance_section_reset = {
      vehicle: financeAgreement.vehicle,
      finance_type: capturedEvent.target.value,
      finance_amount_borrowed: 0,
      hp_interest_rate: 0,
      deposit_amount: 0,
      monthly_payment: 0,
      payment_term: 0,
      finance_start_date: null,
      end_date: null,
      agreement_no: "",
      finance_company: ""
    };
    updateFinanceAgreements(finance_section_reset, index);
    setModalOpen(false);
  };

  const getTotalRepayable = () => {
    if (financeAgreement.payment_term && financeAgreement.monthly_payment) {
      if (financeAgreement.finance_type === "FIXED_MONTHLY_CAPITAL") {
        return (
          paymentSchedule.reduce((a, b) => a + b.interest_accrued, 0) +
          financeAgreement.payment_term * financeAgreement.monthly_payment
        );
      } else if (financeAgreement.finance_type === "REGULAR" || financeAgreement.finance_type === "LEASE_AGREEMENT") {
        return financeAgreement.payment_term * financeAgreement.monthly_payment;
      }
    }
    return 0;
  };


  const getTotalInterest = () => {
    if (
      financeAgreement.payment_term &&
      financeAgreement.monthly_payment &&
      financeAgreement.finance_amount_borrowed
    ) {
      if (financeAgreement.finance_type === "FIXED_MONTHLY_CAPITAL" || financeAgreement.finance_type === "STOCKING_PLAN") {
        return paymentSchedule.reduce((a, b) => a + b.interest_accrued, 0);
      } else if (financeAgreement.finance_type === "REGULAR" || financeAgreement.finance_type === "LEASE_AGREEMENT") {
        return (
          financeAgreement.payment_term * financeAgreement.monthly_payment -
          financeAgreement.finance_amount_borrowed
        );
      }
    }
    return 0;
  };

  const onMonthlyInterestChange = e => {
    let newFinanceAgreement = {...financeAgreement, [e.target.name]: e.target.value};
    const annualInterest = e.target.value * 12;
    newFinanceAgreement.hp_interest_rate = annualInterest;

    updateFinanceAgreements(newFinanceAgreement, index)
    // dispatch(createSetVehicleDataAction(newVehicle));
  };

  const onModificationChange = e => {
    const updatedModification = !modificationChecked;
    setModificationChecked(updatedModification);
    updateFinanceAgreements({...financeAgreement, modification: updatedModification}, index);
  }


  const onClick = e => {
    if (financeAgreement.finance_type !== "NONE") {
      setCapturedEvent(e);
      setModalOpen(true);
    } else {
      onChange(e);
    }
  };
  const onPurchasingChange = e => {
    let newAgreement = {...financeAgreement, [e.target.name]: e.target.value};
    if (newAgreement.vehicle_purchase_price && addonsPurchasePrice) {
      newAgreement.purchase_price = parseFloat(newAgreement.vehicle_purchase_price) + parseFloat(addonsPurchasePrice)
      newAgreement.purchase_price = newAgreement.purchase_price.toFixed(2);
    }
    if (newAgreement.purchase_price && newAgreement.deposit_amount) {
      newAgreement.finance_amount_borrowed =
          (newAgreement.purchase_price - newAgreement.deposit_amount).toFixed(2);
    }

    updateFinanceAgreements(newAgreement, index);
  };
  const onAddonsPurchaseChange = e => {
    setAddonsPurchasePrice(e.target.value);
    let newAgreement = {...financeAgreement};
    if (newAgreement.vehicle_purchase_price && e.target.value) {
      newAgreement.purchase_price = parseFloat(newAgreement.vehicle_purchase_price) + parseFloat(e.target.value)
      newAgreement.purchase_price = newAgreement.purchase_price.toFixed(2);
    }
    updateFinanceAgreements(newAgreement, index);
  }
  const getNewFinanceAgreement = e => {
    let newFinanceAgreement = {...financeAgreement, [e.target.name]: e.target.value};
    return newFinanceAgreement;
  };

  const onCheck = propName => {
    const newFinanceAgreement = getNewFinanceAgreement({
      target: {
        name: propName,
        value: !financeAgreement[propName]
      }
    });
    updateFinanceAgreements(newFinanceAgreement, index);
  };

  const [freezeStart, freezeStartInput, setFreezeStart] = useInput({
    type: "date",
    name: "freeze_start_date",
    label: "Freeze Start Date",
    initialValue: financeAgreement.freeze_start_date,
    onChange: onChange
  });
  const [freezeEnd, freezeEndInput, setFreezeEnd] = useInput({
    type: "date",
    name: "freeze_end_date",
    label: "Freeze End Date",
    initialValue: financeAgreement.freeze_end_date,
    onChange: onChange
  });


  return (
      <Box>
        <Collapsible
            className="title finance-title"
            triggerOpenedClassName="title finance-title"
            trigger={`${financeAgreement.modification ? 'Conversion - ' : ''}${financeAgreement.finance_company ? financeAgreement.finance_company : "No Finance Company Specified"} - ${financeAgreement.finance_start_date ? readableDate(financeAgreement.finance_start_date) : "No Start Date Specified"}`}
            open={index === 0 || !financeAgreement.id}
        >
          <Columns>
            <Columns.Column>
              <Form.Field>
                <Form.Label>Comments</Form.Label>
                <Form.Control>
                  <Form.Input
                      type="text"
                      name="finance_comments"
                      onChange={onChange}
                      value={financeAgreement.finance_comments}
                  />
                </Form.Control>
              </Form.Field>
            </Columns.Column>
          </Columns>
          <Heading size={4}>Purchase Info</Heading>
          <Columns>
            <Columns.Column>
              <Form.Field>
                <Form.Label>Sage Asset ID</Form.Label>
                <Form.Control>
                  <Form.Input
                      type="text"
                      name="sage_asset_id"
                      onChange={onChange}
                      value={financeAgreement.sage_asset_id}
                  />
                </Form.Control>
              </Form.Field>
            </Columns.Column>
            <Columns.Column>
              <Form.Field>
                <Form.Label>Sage Liability ID</Form.Label>
                <Form.Control>
                  <Form.Input
                      type="text"
                      name="sage_liability_id"
                      onChange={onChange}
                      value={financeAgreement.sage_liability_id}
                  />
                </Form.Control>
              </Form.Field>
            </Columns.Column>
            <Columns.Column>
              <Form.Field>
                <Form.Label>Department</Form.Label>
                <Form.Control>
                  <Form.Input
                      type="text"
                      name="department"
                      onChange={onChange}
                      value={financeAgreement.department}
                  />
                </Form.Control>
              </Form.Field>
            </Columns.Column>
            <Columns.Column>
              <Form.Field>
                <Form.Label>Vehicle Purchase Price</Form.Label>
                <Form.Control>
                  <Form.Input
                      type="number"
                      name="vehicle_purchase_price"
                      onChange={onPurchasingChange}
                      value={String(financeAgreement.vehicle_purchase_price)}
                  />
                  <Icon align={"left"} className={"small-icon"}>
                    <FontAwesomeIcon icon={faPoundSign}/>
                  </Icon>
                </Form.Control>
              </Form.Field>
            </Columns.Column>
            <Columns.Column>
              <Form.Field>
                <Form.Label>Addons Purchase Price</Form.Label>
                <Form.Control>
                  <Form.Input
                      type="number"
                      name="addons_purchase_price"
                      onChange={onAddonsPurchaseChange}
                      value={addonsPurchasePrice}
                  />
                  <Icon align={"left"} className={"small-icon"}>
                    <FontAwesomeIcon icon={faPoundSign}/>
                  </Icon>
                </Form.Control>
              </Form.Field>
            </Columns.Column>
            <Columns.Column>
              <Form.Field>
                <Form.Label>Purchase Price</Form.Label>
                <Form.Control>
                  <Form.Input
                      type="number"
                      name="purchase_price"
                      disabled={true}
                      value={String(financeAgreement.purchase_price)}
                  />
                  <Icon align={"left"} className={"small-icon"}>
                    <FontAwesomeIcon icon={faPoundSign}/>
                  </Icon>
                </Form.Control>
              </Form.Field>
            </Columns.Column>
            <Columns.Column>
              <Form.Field>
                <Form.Label>VAT</Form.Label>
                <Form.Control>
                  <Form.Input
                      type="number"
                      name="purchase_price_vat"
                      onChange={onChange}
                      value={String(financeAgreement.purchase_price_vat)}
                  />
                  <Icon align={"left"} className={"small-icon"}>
                    <FontAwesomeIcon icon={faPoundSign}/>
                  </Icon>
                </Form.Control>
              </Form.Field>
            </Columns.Column>
          </Columns>
          <Columns>
            <Columns.Column>
              <Form.Field>
                <Form.Label>Supplier</Form.Label>
                <Form.Control>
                  <AsyncTypeahead
                      useCache={false}
                      id="typeahead"
                      labelKey="display_name"
                      minLength={2}
                      name={"supplier"}
                      onSearch={handleSupplierSearch}
                      onChange={handleSupplierSelect}
                      placeholder={"Search Supplier"}
                      options={searchData}
                      ref={typeahead => setTypeahead(typeahead)}
                      className="typeahead"
                      isLoading={isLoading}
                      selected={financeAgreement.supplier && financeAgreement.supplier.id ? [financeAgreement.supplier] : []}
                  />
                </Form.Control>
              </Form.Field>
            </Columns.Column>
            <Columns.Column>
              <Form.Field>
                <Form.Label>Invoice Number</Form.Label>
                <Form.Control>
                  <Form.Input
                      type="text"
                      name="invoice_number"
                      onChange={onChange}
                      value={financeAgreement.invoice_number}
                  />
                </Form.Control>
              </Form.Field>
            </Columns.Column>
            <Columns.Column>
              <Form.Field>
                <Form.Label>Invoice Date</Form.Label>
                <Form.Control>
                  <Form.Input
                      type="date"
                      name="invoice_date"
                      onChange={onChange}
                      value={String(financeAgreement.invoice_date)}
                  />
                </Form.Control>
              </Form.Field>
            </Columns.Column>
            <Columns.Column>
              <Form.Field>
                <Form.Label>Delivery Date (depr. Start)</Form.Label>
                <Form.Control>
                  <Form.Input
                      type="date"
                      name="depreciation_start_date"
                      onChange={onChange}
                      value={String(financeAgreement.depreciation_start_date)}
                  />
                </Form.Control>
              </Form.Field>
            </Columns.Column>
            <Columns.Column>
              <Form.Field>
                <Form.Label>Annual Depreciation (%)</Form.Label>
                <Form.Control>
                  <Form.Input
                      type="number"
                      name="annual_depreciation_rate"
                      onChange={onChange}
                      value={
                        financeAgreement.annual_depreciation_rate
                      }
                  />
                </Form.Control>
              </Form.Field>
            </Columns.Column>
          </Columns>
          <Columns>
            <Columns.Column>
              <Form.Field>
                <Form.Label>
                  Conversion / Modification Financing
                </Form.Label>
                <Form.Control>
                  <Form.Checkbox
                      name="modification"
                      onChange={onModificationChange}
                      checked={modificationChecked}
                  />
                </Form.Control>
              </Form.Field>
            </Columns.Column>
          </Columns>

        <Columns>
          <Columns.Column>
            <Form.Field className="has-addons">
              <Form.Control>
                <Heading size={4}>Finance Agreement</Heading>
                <Button
                  color={financeAgreement.finance_type === "NONE" ? "primary" : ""}
                  onClick={() =>
                    onClick({
                      target: {name: "finance_type", value: "NONE"}
                    })
                  }
                >
                  None
                </Button>
                <Button
                      color={financeAgreement.finance_type === "GRANT_FINANCE" ? "primary" : ""}
                      onClick={() =>
                          onClick({
                            target: {name: "finance_type", value: "GRANT_FINANCE"}
                          })
                      }
                  >
                    Grant Finance
                </Button>
                <Button
                  color={financeAgreement.finance_type === "HIRE_AGREEMENT" ? "primary" : ""}
                  onClick={() =>
                    onClick({
                      target: {name: "finance_type", value: "HIRE_AGREEMENT"}
                    })
                  }
                >
                  Hire Agreement
                </Button>
                <Button
                  color={financeAgreement.finance_type === "REGULAR" ? "primary" : ""}
                  onClick={() =>
                    onClick({
                      target: {name: "finance_type", value: "REGULAR"}
                    })
                  }
                >
                  Fixed Rate
                </Button>
                <Button
                  color={
                    financeAgreement.finance_type === "LEASE_AGREEMENT"
                      ? "primary"
                      : ""
                  }
                  onClick={() =>
                    onClick({
                      target: {
                        name: "finance_type",
                        value: "LEASE_AGREEMENT"
                      }
                    })
                  }
                >
                  Lease Agreement
                </Button>
                <Button
                  color={
                    financeAgreement.finance_type === "FIXED_MONTHLY_CAPITAL"
                      ? "primary"
                      : ""
                  }
                  onClick={() =>
                    onClick({
                      target: {
                        name: "finance_type",
                        value: "FIXED_MONTHLY_CAPITAL"
                      }
                    })
                  }
                >
                  Variable Rate
                </Button>
                <Button
                  color={
                    financeAgreement.finance_type === "STOCKING_PLAN"
                      ? "primary"
                      : ""
                  }
                  onClick={() =>
                    onClick({
                      target: {
                        name: "finance_type",
                        value: "STOCKING_PLAN"
                      }
                    })
                  }
                >
                  Stocking Plan
                </Button>
              </Form.Control>
            </Form.Field>
          </Columns.Column>
        </Columns>
            {financeAgreement.finance_type === "GRANT_FINANCE" &&
              <NegativeFinanceSection
                  financeAgreement={financeAgreement}
                  onChange={onChange}
                  index={index}
              />

          }
        {financeAgreement.finance_type === "STOCKING_PLAN" &&
          <StockingPlanSection
            updateFinanceAgreements={updateFinanceAgreements}
            financeAgreement={financeAgreement}
            index={index}
          />
        }
        {(financeAgreement.finance_type !== "NONE" && financeAgreement.finance_type !== "STOCKING_PLAN"
            && financeAgreement.finance_type !== 'HIRE_AGREEMENT') && (
          <div>
            <Columns>
              <Columns.Column>
                <Form.Field>
                  <Form.Label>Deposit</Form.Label>
                  <Form.Control>
                    <Form.Input
                      type="number"
                      name="deposit_amount"
                      onChange={onPurchasingChange}
                      value={String(financeAgreement.deposit_amount)}
                    />
                    <Icon align={"left"} className={"small-icon"}>
                      <FontAwesomeIcon icon={faPoundSign}/>
                    </Icon>
                  </Form.Control>
                </Form.Field>
              </Columns.Column>
              <Columns.Column>
                <Form.Field>
                  <Form.Label>Amount Borrowed</Form.Label>
                  <Form.Control>
                    <Form.Input
                      type="number"
                      name="finance_amount_borrowed"
                      onChange={onChange}
                      value={String(financeAgreement.finance_amount_borrowed)}
                    />
                    <Icon align={"left"} className={"small-icon"}>
                      <FontAwesomeIcon icon={faPoundSign}/>
                    </Icon>
                  </Form.Control>
                </Form.Field>
              </Columns.Column>
              <Columns.Column>
                <Form.Field>
                  <Form.Label>
                    {financeAgreement.finance_type === "FIXED_MONTHLY_CAPITAL" || financeAgreement.finance_type === "STOCKING_PLAN"
                      ? "Monthly Capital Payment"
                      : "Monthly Payment"}
                  </Form.Label>
                  <Form.Control>
                    <Form.Input
                      type="number"
                      name="monthly_payment"
                      onChange={onChange}
                      value={String(financeAgreement.monthly_payment)}
                    />
                    <Icon align={"left"} className={"small-icon"}>
                      <FontAwesomeIcon icon={faPoundSign}/>
                    </Icon>
                  </Form.Control>
                </Form.Field>
              </Columns.Column>
              <Columns.Column>
                <Form.Field>
                  <Form.Label>{financeAgreement.finance_type === "STOCKING_PLAN" ? "Payment Months (exc. balloon payment)" : "Payment Term (Months) "}</Form.Label>
                  <Form.Control>
                    <Form.Input
                      type="number"
                      name="payment_term"
                      onChange={onChange}
                      value={String(financeAgreement.payment_term)}
                    />
                  </Form.Control>
                </Form.Field>
              </Columns.Column>
              {financeAgreement.finance_type === "STOCKING_PLAN" &&
                <Columns.Column>
                  <Form.Field>
                    <Form.Label>Agreement Start Date (Free period start)</Form.Label>
                    <Form.Control>
                      <Form.Input
                        type="date"
                        name="sp_start_date"
                        onChange={onChange}
                        value={financeAgreement.sp_start_date || ""}
                      />
                    </Form.Control>
                  </Form.Field>
                </Columns.Column>
              }
              <Columns.Column>
                <Form.Field>
                  <Form.Label>{financeAgreement.finance_type === "STOCKING_PLAN" ? "Free Period End (Interest Start)" : "Finance Start Date"}</Form.Label>
                  <Form.Control>
                    <Form.Input
                      type="date"
                      name="finance_start_date"
                      onChange={onChange}
                      value={financeAgreement.finance_start_date || ""}
                    />
                  </Form.Control>
                </Form.Field>
              </Columns.Column>
              <Columns.Column>
                <Form.Field>
                  <Form.Label>Finance End Date</Form.Label>
                  <Form.Control>
                    <Form.Input
                      type="date"
                      name="end_date"
                      onChange={onChange}
                      value={financeAgreement.end_date || ""}
                    />
                  </Form.Control>
                </Form.Field>
              </Columns.Column>
            </Columns>
            <Columns>
              {financeAgreement.finance_type === "STOCKING_PLAN" &&
                <Columns.Column>
                  <Form.Field>
                    <Form.Label>Balloon Payment</Form.Label>
                    <Form.Control>
                      <Form.Input
                        type="number"
                        name="balloon_payment"
                        onChange={onPurchasingChange}
                        value={String(financeAgreement.balloon_payment)}
                      />
                      <Icon align={"left"} className={"small-icon"}>
                        <FontAwesomeIcon icon={faPoundSign}/>
                      </Icon>
                    </Form.Control>
                  </Form.Field>
                </Columns.Column>
              }
              <Columns.Column>
                <Form.Field>
                  <Form.Label>Finance Company</Form.Label>
                  <Form.Control>
                    <Form.Input
                      type="text"
                      name="finance_company"
                      onChange={onChange}
                      value={financeAgreement.finance_company}
                    />
                  </Form.Control>
                </Form.Field>
              </Columns.Column>
              <Columns.Column>
                <Form.Field>
                  <Form.Label>Agreement Number</Form.Label>
                  <Form.Control>
                    <Form.Input
                      type="text"
                      name="agreement_no"
                      onChange={onChange}
                      value={String(financeAgreement.agreement_no) || ""}
                    />
                  </Form.Control>
                </Form.Field>
              </Columns.Column>
            </Columns>
            <Columns>
              {financeAgreement.finance_type !== 'STOCKING_PLAN' && financeAgreement.finance_type !== 'NONE'
                  && financeAgreement.finance_type !== 'HIRE_AGREEMENT' && financeAgreement.finance_type !== 'FIXED_MONTHLY_CAPITAL'
                  &&
                freezeStartInput
              }
              {financeAgreement.finance_type !== 'STOCKING_PLAN' && financeAgreement.finance_type !== 'NONE'
                  && financeAgreement.finance_type !== 'HIRE_AGREEMENT' && financeAgreement.finance_type !== 'FIXED_MONTHLY_CAPITAL'
                  &&
                freezeEndInput
              }
            </Columns>
            <Columns>
              <Columns.Column>
                <Form.Field>
                  <Form.Label>Total Repayable</Form.Label>
                  <Form.Control>
                    <Form.Input
                      type="number"
                      name="total_repayable"
                      onChange={onChange}
                      value={getTotalRepayable().toFixed(2)}
                      disabled
                      readOnly
                    />
                    <Icon align={"left"} className={"small-icon"}>
                      <FontAwesomeIcon icon={faPoundSign}/>
                    </Icon>
                  </Form.Control>
                </Form.Field>
              </Columns.Column>

                  <Columns.Column>
                    <Form.Field>
                      <Form.Label>Total Interest Repayable</Form.Label>
                      <Form.Control>
                        <Form.Input
                            type="number"
                            name="interest_repayable"
                            onChange={onChange}
                            value={getTotalInterest().toFixed(2)}
                            disabled
                            readOnly
                        />
                        <Icon align={"left"} className={"small-icon"}>
                          <FontAwesomeIcon icon={faPoundSign}/>
                        </Icon>
                      </Form.Control>
                    </Form.Field>
                  </Columns.Column>
                  {
                      financeAgreement.finance_type === "STOCKING_PLAN" &&
                      <Columns.Column>
                        <Form.Field>
                          <Form.Label>Monthly Interest Rate (%)</Form.Label>
                          <Form.Control>
                            <Form.Input
                                type="number"
                                name="sp_monthly_interest_rate"
                                onChange={onMonthlyInterestChange}
                                value={
                                  financeAgreement.sp_monthly_interest_rate ? financeAgreement.sp_monthly_interest_rate : ""
                                }
                            />
                          </Form.Control>
                        </Form.Field>
                      </Columns.Column>
                  }
                  <Columns.Column>
                    <Form.Field>
                      <Form.Label>Interest Rate (%)</Form.Label>
                      <Form.Control>
                        <Form.Input
                            type="number"
                            name="hp_interest_rate"
                            onChange={onChange}
                            value={
                              financeAgreement.hp_interest_rate ? financeAgreement.hp_interest_rate : ""
                            }
                            disabled={financeAgreement.finance_type === "REGULAR" || financeAgreement.finance_type === "STOCKING_PLAN" || financeAgreement.finance_type === "LEASE_AGREEMENT"}
                            readOnly={financeAgreement.finance_type === "REGULAR" || financeAgreement.finance_type === "STOCKING_PLAN" || financeAgreement.finance_type === "LEASE_AGREEMENT"}
                        />
                      </Form.Control>
                    </Form.Field>
                  </Columns.Column>
                </Columns>
                <Columns>
                  <Columns.Column>
                    <Form.Field>
                      <Form.Label>Finance Settlement Amount Paid</Form.Label>
                      <Form.Control>
                        <Form.Input
                            type="number"
                            name="finance_early_settlement_amount_paid"
                            onChange={onChange}
                            value={String(
                                financeAgreement.finance_early_settlement_amount_paid || ""
                            )}
                        />
                        <Icon align={"left"} className={"small-icon"}>
                          <FontAwesomeIcon icon={faPoundSign}/>
                        </Icon>
                      </Form.Control>
                    </Form.Field>
                  </Columns.Column>
                  <Columns.Column>
                    <Form.Field>
                      <Form.Label>Finance Early Settlement Date</Form.Label>
                      <Form.Control>
                        <Form.Input
                            type="date"
                            name="finance_early_settlement_date"
                            onChange={onChange}
                            value={financeAgreement.finance_early_settlement_date || ""}
                        />
                      </Form.Control>
                    </Form.Field>
                  </Columns.Column>
                </Columns>
                <Columns>
                  {(financeAgreement.finance_type !== "REGULAR" && financeAgreement.finance_type !== "LEASE_AGREEMENT") && (
                      <Columns.Column>
                        <Form.Field>
                          <Form.Label>This Months Payment</Form.Label>
                          <Form.Control>
                            <Form.Input
                                type="text"
                                value={
                                  financeAgreement.this_months_payment
                                      ? financeAgreement.this_months_payment
                                      : 0.0
                                }
                                disabled
                                readOnly
                            />
                            <Icon align={"left"} className={"small-icon"}>
                              <FontAwesomeIcon icon={faPoundSign}/>
                            </Icon>
                          </Form.Control>
                        </Form.Field>
                      </Columns.Column>
                  )}
                  <Columns.Column>
                    <Form.Field>
                      <Form.Label>Remaining Capital</Form.Label>
                      <Form.Control>
                        <Form.Input
                            type="text"
                            value={(financeAgreement.remaining_balance - financeAgreement.hp_remaining_interest).toFixed(2) || 0.0}
                            disabled
                            readOnly
                        />
                        <Icon align={"left"} className={"small-icon"}>
                          <FontAwesomeIcon icon={faPoundSign}/>
                        </Icon>
                      </Form.Control>
                    </Form.Field>
                  </Columns.Column>
                  <Columns.Column>
                    <Form.Field>
                      <Form.Label>Remaining Interest</Form.Label>
                      <Form.Control>
                        <Form.Input
                            type="text"
                            value={financeAgreement.hp_remaining_interest ? (financeAgreement.hp_remaining_interest.toFixed(2) || 0.0) : 0.0}
                            // value={getRemainingInterest(vehicle, new Date(Date.now()) || 0.0)}
                            disabled
                            readOnly
                        />
                        <Icon align={"left"} className={"small-icon"}>
                          <FontAwesomeIcon icon={faPoundSign}/>
                        </Icon>
                      </Form.Control>
                    </Form.Field>
                  </Columns.Column>
                  <Columns.Column>
                    <Form.Field>
                      <Form.Label>Outstanding Balance</Form.Label>
                      <Form.Control>
                        <Form.Input
                            type="text"
                            value={financeAgreement.remaining_balance || 0.0}
                            disabled
                            readOnly
                        />
                        <Icon align={"left"} className={"small-icon"}>
                          <FontAwesomeIcon icon={faPoundSign}/>
                        </Icon>
                      </Form.Control>
                    </Form.Field>
                  </Columns.Column>
                  {financeAgreement.finance_early_settlement_date &&
                      <Columns.Column>
                        <Form.Field>
                          <Form.Label>Early Settlement Charges</Form.Label>
                          <Form.Control>
                            <Form.Input
                                type="text"
                                value={((financeAgreement.remaining_balance - financeAgreement.hp_remaining_interest) - financeAgreement.finance_early_settlement_amount_paid).toFixed(2) || 0.0}
                                disabled
                                readOnly
                            />
                            <Icon align={"left"} className={"small-icon"}>
                              <FontAwesomeIcon icon={faPoundSign}/>
                            </Icon>
                          </Form.Control>
                        </Form.Field>
                      </Columns.Column>
                  }
                  {financeAgreement.finance_early_settlement_date &&
                      <Columns.Column>
                        <Form.Field>
                          <Form.Label>Potential Overpayment</Form.Label>
                          <Form.Control>
                            <Form.Input
                                type="text"
                                value={
                                  ((financeAgreement.remaining_balance - financeAgreement.hp_remaining_interest) - financeAgreement.finance_early_settlement_amount_paid) < (-1 * financeAgreement.monthly_payment) ? (1 * financeAgreement.monthly_payment).toFixed(2) : 0.0.toFixed(2)
                                }
                                disabled
                                readOnly
                            />
                            <Icon align={"left"} className={"small-icon"}>
                              <FontAwesomeIcon icon={faPoundSign}/>
                            </Icon>
                          </Form.Control>
                        </Form.Field>
                      </Columns.Column>
                  }
                </Columns>
              </div>
          )}
          {
              financeAgreement.finance_type === 'HIRE_AGREEMENT' && (
                  <HireAgreementSection
                      financeAgreement={financeAgreement}
                      onChange={onChange}
                  />
              )
          }
          {financeAgreement.finance_type !== 'HIRE_AGREEMENT' && (
              <SaleInfo
                  financeAgreement={financeAgreement}
                  onChange={onChange}
                  endpoint={endpoint}
              />
          )}
          <Columns>
            <Columns.Column>
              <Button
                  color="danger"
                  onClick={() => removeFinanceAgreement(index)}
              >
                Remove Purchase/Finance Agreement/Sale Section
              </Button>
            </Columns.Column>
            {financeAgreement.sold_date &&
                <Columns.Column>
                    <Form.Field>
                        <ReactToPrint
                            trigger={() => (
                                <Button color="warning" fullwidth>
                                    Print Pro Forma and Collection Sheet
                                </Button>
                            )}
                            content={() => printSaleInvoice.current}
                            bodyClass="print-portrait"
                            pageStyle="@page {
                          size: A4 portrait;
                        }"
                        />
                    </Form.Field>
                </Columns.Column>
            }
          </Columns>
          <Modal closeOnBlur show={modalOpen} onClose={() => setModalOpen(false)}>
            <Modal.Content>
              <Section style={{backgroundColor: "grey"}}>
                <Box>
                  <Columns>
                    <Columns.Column>
                      <Heading className="is-centered">Warning</Heading>
                      <Heading size={6}>
                        Are you sure you want to change the finance agreement type?
                      </Heading>
                      <p>
                        Changing the finance agreement type will overwrite the
                        current finance agreement details
                      </p>
                    </Columns.Column>
                  </Columns>
                  <Columns>
                    <Columns.Column>
                      <Button color="success" onClick={onConfirmChange}>
                        Continue
                      </Button>
                    </Columns.Column>
                    <Columns.Column>
                      <Button color="danger" onClick={() => setModalOpen(false)}>
                        Cancel
                      </Button>
                    </Columns.Column>
                  </Columns>
                </Box>
              </Section>
            </Modal.Content>
          </Modal>
        </Collapsible>
        <PaymentModal
          key={stateUpdate}
          open={paymentModal}
          setOpen={setPaymentModal}
          financeAgreement={financeAgreement}
          endpoint={endpoint}
          vehicle={vehicle}
        />
        <div style={{display: "none"}}>
          <div>
            <PrintSalesInvoice ref={printSaleInvoice} finance={financeAgreement} vehicle={vehicle} serviceData={serviceData} key={stateUpdate}/>
          </div>
        </div>
      </Box>
  );
}

export default FinanceSection;
