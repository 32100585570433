import React from "react";
import {Picker} from "../Utils/Picker";


export default function MovementRatePicker({options, onChange, movement, editable, setEditable, inlineEdit, onSave, disabled, label, blank=false}){

    return (
        <Picker
            name={"movement_rate"}
            label={label}
            value={movement.movement_rate}
            blank={blank}
            options={options}
            optionField={"name"}
            disabled={disabled}
            onChange={onChange}
            editable={editable}
            setEditable={setEditable}
            inlineEdit={inlineEdit}
            onSave={onSave}
        />
    )



}