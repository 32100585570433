import {Button, Form} from "react-bulma-components";
import { Columns } from "react-bulma-components";
import React, { useState } from "react";

function useButtonSelect({ label, initialValue, options, prop, callback }) {
  const [value, setValue] = useState(initialValue || "");

  const onChange = e => {
    setValue(e.target.value);
    if (callback) {
      callback(e);
    }
  };

  const buttonSelect = (
    <Columns.Column>
      <Form.Field>
        <Form.Control>
          <Form.Label>{label}</Form.Label>
            {options.map(item => (
              <Button key={item.name}
                      value={item.value}
                      color={value === item.value ? "primary" : ""}
                      onClick={() => onChange({target: {name: prop, value: item.value}})}
              >
                {item.name}
              </Button>
            ))}
        </Form.Control>
      </Form.Field>
    </Columns.Column>
  );
  return [value, buttonSelect, setValue];
}

export default useButtonSelect;