import React from "react";
import logo from "../Nav/img/Logo.jpg";
import {Columns, Heading, Table} from "react-bulma-components";
import {readableDate} from "../../utils";
import {parseInt} from "lodash";

class PrintMovements extends React.Component {

    MovementRow(movement){
        return (
            <tr>
                <td>{movement.driver ? movement.driver.name : ""}</td>
                <td>{movement.vehicle}</td>
                <td>{movement.vehicle_used ? movement.vehicle_used.registration : ""}</td>
                <td>{readableDate(movement.delivery_date)}</td>
                <td>{movement.start_address}</td>
                <td>{movement.end_address}</td>
                <td>{movement.start_time}</td>
                <td>{movement.end_time}</td>
                <td>{movement.customer ? movement.customer.display_name : ""}</td>
                <td>{movement.comments}</td>
            </tr>
        )
    }

    compareFunction(movement1, movement2){
        const d1 = new Date(movement1.delivery_date);
        const d2 = new Date(movement2.delivery_date);
        if(d1 < d2){
            return -1
        }
        else if(d1 > d2){
            return 1
        }
        else if(d1 === d2) {
            let startA = movement1.start_time;
            let startB = movement2.start_time;
            startA = startA.slice(0, 2).concat(startA.slice(3, 5));
            startA = parseInt(startA);
            startB = startB.slice(0, 2).concat(startB.slice(3, 5));
            startB = parseInt(startB);


            if (startA < startB) {
                return -1
            } else if (startA > startB) {
                return 1
            } else if (startA === startB) {
                return 0
            }
        }
    }

    render(){
        let movements = Object.values(this.props.data);
        movements = movements.sort(this.compareFunction)
        return(
            <div className={"print-background"}>
                <Columns>
                  <img src={logo} style={{width: '110px', height: '35px'}} alt="Rentals Direct Ltd" />
                  <Heading>Movements List</Heading>
                </Columns>
                <Columns>
                    <Columns.Column>
                        <Table>
                            <thead>
                                <tr>
                                    <th>Driver</th>
                                    <th>Vehicle To Move</th>
                                    <th>Vehicle Used For Movement</th>
                                    <th>Delivery Date</th>
                                    <th>Start Address</th>
                                    <th>End Address</th>
                                    <th>Start Time</th>
                                    <th>Time To Be Completed By</th>
                                    <th>Customer</th>
                                    <th>Comments</th>
                                </tr>
                            </thead>
                            <tbody>
                            {movements.map((movement) => (
                                this.MovementRow(movement)
                            ))}
                            </tbody>
                        </Table>
                    </Columns.Column>
                </Columns>
            </div>
        )
    }
}

export default PrintMovements;