import React, {useState} from "react";
import {Box, Button, Columns, Form} from "react-bulma-components";
import Collapsible from "react-collapsible";


export default function CreatePaymentType({onSave}){
    const [typeName, setTypeName] = useState("")

    return (
        <Box>
            <Collapsible
                trigger={"Add Payment Type"}
                triggerOpenedClassName={"title"}
                className={"title"}
            >
                <Columns>
                    <Columns.Column>
                        <Form.Control>
                            <Form.Label>Name</Form.Label>
                            <Form.Input
                                type={"text"}
                                value={typeName}
                                onChange={(e) => setTypeName(e.target.value)}
                            />
                        </Form.Control>
                    </Columns.Column>
                    <Columns.Column/>
                    <Columns.Column/>
                    <Columns.Column/>
                </Columns>
                <Columns>
                    <Columns.Column>
                        <Button
                            color={"success"}
                            onClick={()=>{
                                onSave({name: typeName});
                                setTypeName("");
                            }}
                            fullwidth
                        >
                            Create
                        </Button>
                    </Columns.Column>
                </Columns>
            </Collapsible>
        </Box>
    )


}