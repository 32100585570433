import React, {useRef, useState} from "react";
import {useMovementsData} from "../../hooks/useMovementsData";
import {
    getDataFresh, getDriversData,
    getMovementFiguresData, getMovementRatesData,
    getMovementsData, getMovementTypesData
} from "../../state/selectors";
import {useDispatch, useSelector} from "react-redux";
import LoadingSpinner from "../Utils/Spinner";
import {Box, Button, Columns, Heading, Pagination, Table, Form} from "react-bulma-components";
import MovementRow from "./MovementRow";
import CreateMovement from "./CreateMovement";
import axios from "axios";
import {createSetDataFreshAction, createSetMovementsDataAction} from "../../actions/dataActions";
import {NotificationManager} from "react-notifications";
import {formatDate, movementCost} from "../../utils";
import {useMovementFigureData} from "../../hooks/useMovementFigureData";
import MovementFigures from "./MovementFigures";
import {useMovementsRateData} from "../../hooks/useMovementsRateData";
import useSelect from "../../hooks/useSelect";
import useInput from "../../hooks/useInput";
import SearchBar from "../Utils/SearchBar";
import {useMovementTypesData} from "../../hooks/useMovementTypesData";
import PrintMovements from "./PrintMovementList";
import ReactToPrint from "react-to-print";
import FAIcon from "../Icon/FAIcon";
import {useDriversData} from "../../hooks/useDriversData";
import FilterBlankHeading from "../Invoicing/FilterBlankHeading";
import FilterHeading from "../Invoicing/FilterHeading";



export default function MovementView(props){
    let [page, setPage] = useState(1);
    const componentRef = useRef();
    const [searchText, setSearchText] = useState()
    const [currentSearch, setCurrentSearch] = useState();
    let params = {page: page}
    let dataFresh = useSelector(getDataFresh);
    const [selected, setSelected] = useState({});
    const [filters, setFilters] = useState({});
    const [createModalOpen, setCreateModalOpen] = useState(false);

    const headings = [
        {
            label: "Movement No.",
            show: true
        },
        {
            label: "Vehicle To Move",
            show: true
        },
        {
            label: "Type",
            show: true
        },
        {
            label: "Vehicle Used For Movement",
            show: true
        },
        {
            label: "Delivery Date",
            show: true
        },
        {
            label: "Start Address",
            show: true
        },
        {
            label: "End Address",
            show: true
        },
        {
            label: "Start Time",
            show: true
        },
        {
            label: "Time To Be Completed By",
            show: true
        },
        {
            label: "Customer",
            show: true
        },
        {
            label: "Driver",
            field:"driver",
            labelField:"driver__name",
            show: true
        }
    ]


    const options = [
        {name: "Normal", value: "NORMAL"},
        {name: "Quote", value: "QUOTE"},
        {name: "Completed", value: "COMPLETED"}
    ];

    const onSelectCheck = item => {
        let newSelection = { ...selected };
        if (Object.keys(selected).indexOf(item.id.toString()) === -1) {
          newSelection[item.id.toString()] = item;
        } else {
          delete newSelection[item.id.toString()];
        }
        setSelected(newSelection);
    };

    const toggleSelectVisible = () => {
        let newSelected = { ...selected };
        let selectedKeys = Object.keys(newSelected);
        const notPresent = data.results.find(
          movement => selectedKeys.indexOf(movement.id.toString()) === -1
        );

        if (notPresent) {
          data.results.forEach(item => {
            newSelected[item.id.toString()] = item;
          });
        } else {
          data.results.forEach(item => {
            delete newSelected[item.id.toString()];
          });
        }
        setSelected(newSelected);
    };

    const clearSelected = () => {
        setSelected({});
    };

    const [statusOption, statusOptionsInput] = useSelect({
        label: "Status",
        initialValue: "NORMAL",
        options: options,
        callback: clearSelected
    });
    const [startDate, startDateInput] = useInput({
        type: "date",
        label: "Start Date",
    })
    const [endDate, endDateInput] = useInput({
        type: "date",
        label: "End Date",
    })
    if(startDate){
        params.start = startDate
    }
    if(endDate){
        params.end = endDate
    }
    if (currentSearch) {
        params.search = currentSearch;
    }
    if(statusOption){
        params.view = statusOption
    }
    for (let filter in filters) {
        if (filters[filter][0] && filters[filter][0][filter]) {
          params[filter] = filters[filter][0][filter];
        }
    }

    let loaded = useMovementsData(params, dataFresh);
    let figuresLoaded = useMovementFigureData(params, dataFresh);
    let movementRateLoaded = useMovementsRateData({}, dataFresh);
    let movementTypeLoaded = useMovementTypesData({}, dataFresh);
    let driversLoaded = useDriversData({}, dataFresh);
    let figureData = useSelector(getMovementFiguresData);
    let data = useSelector(getMovementsData);
    let rateData = useSelector(getMovementRatesData);
    let typeData = useSelector(getMovementTypesData);
    let drivers = useSelector(getDriversData);
    const dispatch = useDispatch();

    const onChange = (e, index) => {
        let newMovement = {...data.results[index], [e.target.name]:e.target.value}
        newMovement = movementCost(newMovement)
        data.results[index] = newMovement;
        dispatch(createSetMovementsDataAction(data.results))
    }
    const onRateChange = (e, index) => {
        let toUpdate ={
            target: {
                name: e.target.name,
                value: movementRateObjects[e.target.value]
            }
        }
        onChange(toUpdate, index);
    };


    const onComplete = (data) => {
        const conf = {
            url: props.endpoint+"movementcomplete/"+data.id,
            method: "put",
            data: data
        };
        axios(conf).then((res) => {
            dispatch(createSetDataFreshAction(dataFresh+1));
            clearSelected();
        });
    }

    const onDelete = (data) => {
        const conf = {
            url: props.endpoint+"movement/"+data.id,
            method: "delete"
        }
        axios(conf).then((res) => {
            dispatch(createSetDataFreshAction(dataFresh+1));
            clearSelected();
        }).catch(() => {
            NotificationManager.error("There was an error deleting that movement");
        })
    };
    const onQuoteToMovement = (data) => {
        const url = props.endpoint+"movementquote/"+data.id;
        const conf = {
            url: url,
            method: "put"
        }
        axios(conf).then(res => {
            dispatch(createSetDataFreshAction(dataFresh + 1))
        })
    };
    const onSave = (data) => {
        const conf = {
            url: props.endpoint+"movement/"+data.id,
            method: "put",
            data: data
        }
        axios(conf).then(() => {
            dispatch(createSetDataFreshAction(dataFresh + 1));
            clearSelected();
        }).catch(()=> {
            NotificationManager.error("There was an error saving the movement")
        })
    };

    const handleSearch = () => {
        setPage(1);
        setCurrentSearch(searchText);
    };

    const clear = () => {
        setSearchText("");
        setCurrentSearch("");
        setPage(1);
    };


    if(!loaded || !movementRateLoaded || !figuresLoaded || !movementTypeLoaded || !driversLoaded){
        return <LoadingSpinner/>
    }

    const movementRateObjects = rateData.results.reduce((obj, item) => {
        return {
          ...obj,
          [item["id"]]: item
        };
    }, {});

    const movementTypeObjects = typeData ? typeData.results.reduce((obj, item) => {
        return {
          ...obj,
          [item["id"]]: item
        };
    }, {}): {};

    const driverObjects = drivers ? drivers.results.reduce((obj, item) => {
        return {
          ...obj,
          [item["id"]]: item
        };
    }, {}): {};

    return (
        <div>
            <Box>
                <Columns>
                    <Columns.Column>
                        <Columns.Column>
                            <Heading>Movements</Heading>
                        </Columns.Column>
                    </Columns.Column>
                    <Columns.Column>
                        <Button
                            color={"success"}
                            onClick={() => {
                                setCreateModalOpen(true)
                            }}
                        >
                            Create Movement +
                        </Button>
                    </Columns.Column>
                </Columns>
                <Columns>
                    <Columns.Column>
                        {statusOptionsInput}
                    </Columns.Column>
                    <Columns.Column>
                        <Form.Label>Search</Form.Label>
                        <SearchBar
                            handleSearch={handleSearch}
                            setSearchText={setSearchText}
                            searchText={searchText}
                            clear={clear}
                        />
                    </Columns.Column>
                    {startDateInput}
                    {endDateInput}
                    <Columns.Column></Columns.Column>
                </Columns>
            </Box>
            <MovementFigures data={figureData} dataFresh={dataFresh} loaded={figuresLoaded}/>
            <Box>
                <Columns>
                    <Columns.Column>
                        <Table size={"fullwidth"}>
                            <thead>
                            <tr>
                                {headings.map(heading => {
                                    if (!heading.field && heading.show) {
                                      return <th key={heading.label}>{heading.label}</th>;
                                    }
                                    if (heading.blank) {
                                      return (
                                        <FilterBlankHeading
                                          key={heading.label}
                                          heading={heading}
                                          setFilters={f => {
                                            setPage(1);
                                            setFilters(f);
                                          }}
                                          filters={filters}
                                        />
                                      );
                                    }
                                    if(heading.show) {
                                        return (
                                            <FilterHeading
                                                key={heading.label}
                                                heading={heading}
                                                setFilters={f => {
                                                    setPage(1);
                                                    setFilters(f);
                                                }}
                                                filters={filters}
                                                options={data.filter_list[heading.field]}
                                            />
                                        );
                                    }
                                  })}
                                <th></th>
                                {statusOption === "QUOTE" && <th></th>}
                                {statusOption !== "QUOTE" &&
                                    <th>
                                    <Button
                                        onClick={toggleSelectVisible}
                                        color={
                                            data.results.find(movement => Object.keys(selected).indexOf(movement.id.toString()) === -1) ? "" : "danger"
                                        }
                                    >
                                        {data.results.find(movement => Object.keys(selected).indexOf(movement.id.toString()) === -1) ?
                                            (
                                            <div>
                                                <FAIcon size="small" icon={["fas", "check"]}/>
                                                <span>Visible</span>
                                            </div>
                                        ) : (
                                            <div>
                                                <FAIcon size="small" icon={["fas", "times"]}/>
                                                <span>Visible</span>
                                            </div>
                                        )}
                                    </Button>
                                    </th>
                                }
                            </tr>
                            </thead>
                            <tbody>
                            {data.results.map(((movement, index) => (
                                <MovementRow
                                    movement={movement}
                                    onDelete={onDelete}
                                    onChange={onChange}
                                    index={index}
                                    onSave={onSave}
                                    rateOptions={rateData}
                                    onRateChange={onRateChange}
                                    view={statusOption}
                                    quoteToMovement={onQuoteToMovement}
                                    onComplete={onComplete}
                                    movementTypes={typeData}
                                    movementTypeObjects={movementTypeObjects}
                                    endpoint={props.endpoint}
                                    disabled={movement.completed}
                                    onSelectCheck={onSelectCheck}
                                    selected={Object.keys(selected).indexOf(movement.id.toString()) !== -1}
                                    isJob={false}
                                    driverObjects={driverObjects}
                                    driverOptions={drivers}
                                />
                            )))}
                            </tbody>
                        </Table>
                    </Columns.Column>
                </Columns>
                <Pagination
                    showFirstLast={true}
                    onChange={setPage}
                    current={page}
                    total={Math.ceil(data.count / 25)}
                >{" "}</Pagination>
            </Box>
            <Box>
                <Columns>
                    <Columns.Column>
                        <ReactToPrint
                          trigger={() => (
                            <Button
                              color={Object.entries(selected).length ? "success" : "warning"}
                              fullwidth
                              disabled={Object.entries(selected).length < 1}
                            >
                              Print Selected
                            </Button>
                          )}
                          content={() => componentRef.current}
                        />
                    </Columns.Column>
                </Columns>
            </Box>
            <CreateMovement
                open={createModalOpen}
                setOpen={setCreateModalOpen}
                endpoint={props.endpoint}
                dataFresh={dataFresh}
                movementRateOptions={rateData}
                movementRateObjects={movementRateObjects}
                movementTypeObjects={movementTypeObjects}
                movementTypeOptions={typeData}
                driverObjects={driverObjects}
                driverOptions={drivers}
            />
            <div style={{display: "none"}}>
                <PrintMovements data={selected} ref={componentRef}/>
            </div>
        </div>
    );
}