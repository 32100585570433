import React from "react";
import {Box, Columns} from "react-bulma-components";

export default function MovementFigures({data, dataFresh, loaded}){



    return (loaded &&
        <Box>
            <Columns>
                <Columns.Column>
                    WORKSHOP TOTAL : £{data.workshop.cost__sum ? data.workshop.cost__sum.toFixed(2) : 0}
                </Columns.Column>
                <Columns.Column>
                    RECOVERY TOTAL : £{data.recovery.cost__sum ? data.recovery.cost__sum.toFixed(2) :  0}
                </Columns.Column>
            </Columns>
        </Box>
    )
}