import React, {useState} from "react";
import useEditableTableTextCell from "../../../hooks/useEditableTableTextCell";
import useEditableTableCostCell from "../../../hooks/useEditableTableCostCell";
import {Button} from "react-bulma-components";
import useEditableTableTextAreaCell from "../../../hooks/useEditableTableTextAreaCell";


export default function MovementRateRow({rate, dataFresh, handleChange, handleSave, index, onDelete}){
    const [editable, setEditable] = useState(false);
    const onChange = e => {
        handleChange(e,index)
    }
    const onSave = e => {
        handleSave(rate);
        setEditable(false);
    }
    return (
        <tr>
            <td>
                {useEditableTableTextCell({
                    propName: "name",
                    value: rate.name,
                    onSave,
                    onChange,
                    editable,
                    setEditable,
                    inlineEdit: true
                })}
            </td>
            <td>
                {useEditableTableCostCell({
                    propName: "cost_per_mile",
                    value: rate.cost_per_mile,
                    onChange,
                    onSave,
                    editable,
                    setEditable,
                    inlineEdit: true
                })}
            </td>
            <td>
                {useEditableTableCostCell({
                    propName: "vehicle_cost",
                    value: rate.vehicle_cost,
                    onSave,
                    onChange,
                    editable,
                    setEditable,
                    inlineEdit: true
                })}
            </td>
            <td>
                {useEditableTableCostCell({
                    propName: "additional_time_cost",
                    value: rate.additional_time_cost,
                    onChange,
                    onSave,
                    editable,
                    setEditable,
                    inlineEdit: true
                })}
            </td>
            <td>
                {useEditableTableCostCell({
                    propName: "callout_cost",
                    value: rate.callout_cost,
                    onChange,
                    onSave,
                    editable,
                    setEditable,
                    inlineEdit: true
                })}
            </td>
            <td>
                {useEditableTableTextAreaCell({
                    propName:"comments",
                    value: rate.comments,
                    onSave,
                    onChange,
                    editable,
                    setEditable,
                    inlineEdit: true
                })}
            </td>
            <td>
                <Button
                    color={"danger"}
                    onClick={() => onDelete(rate)}
                >
                    X
                </Button>
            </td>
        </tr>
    )


}