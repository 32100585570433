import React, {useState} from "react";
import {Box, Button, Columns, Form, Icon} from "react-bulma-components";
import Collapsible from "react-collapsible";
import {faPoundSign} from "@fortawesome/free-solid-svg-icons";
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";


export default function NewMovementRate({onSave}){
    const [rate, setRate] = useState({
        name: "",
        cost_per_mile: 0,
        vehicle_cost: 0,
        additional_time_cost: 0,
        callout_cost: 0,
        comments: ""
    })
    const onChange = e => {
        let newRate = {...rate, [e.target.name]: e.target.value}
        setRate(newRate);
    }
    return (
        <Box>
            <Collapsible
                className={"title"}
                trigger={"Add Movement Rate"}
                triggerOpenedClassName={"title"}
            >
                <Columns>
                    <Columns.Column>
                        <Form.Field>
                            <Form.Label>Name</Form.Label>
                            <Form.Input
                                name={"name"}
                                type={"text"}
                                onChange={onChange}
                                value={rate.name}
                            />
                        </Form.Field>
                    </Columns.Column>
                    <Columns.Column>
                        <Form.Field>
                            <Form.Label>Cost Per Mile</Form.Label>
                            <Form.Control className={"has-addons"}>
                                <Form.Input
                                    type={"number"}
                                    name={"cost_per_mile"}
                                    value={rate.cost_per_mile}
                                    onChange={onChange}
                                />
                                <Icon align={"left"} className={"small-icon"}>
                                  <FontAwesomeIcon icon={faPoundSign}/>
                                </Icon>
                            </Form.Control>
                        </Form.Field>
                    </Columns.Column>
                    <Columns.Column>
                        <Form.Field>
                            <Form.Label>Vehicle Cost</Form.Label>
                            <Form.Control className={"has-addons"}>
                                <Form.Input
                                    type={"number"}
                                    name={"vehicle_cost"}
                                    value={rate.vehicle_cost}
                                    onChange={onChange}
                                />
                                <Icon align={"left"} className={"small-icon"}>
                                  <FontAwesomeIcon icon={faPoundSign}/>
                                </Icon>
                            </Form.Control>
                        </Form.Field>
                    </Columns.Column>
                    <Columns.Column>
                        <Form.Field>
                            <Form.Label>Additional Time Cost</Form.Label>
                            <Form.Control className={"has-addons"}>
                                <Form.Input
                                    type={"number"}
                                    name={"additional_time_cost"}
                                    value={rate.additional_time_cost}
                                    onChange={onChange}
                                />
                                <Icon align={"left"} className={"small-icon"}>
                                  <FontAwesomeIcon icon={faPoundSign}/>
                                </Icon>
                            </Form.Control>
                        </Form.Field>
                    </Columns.Column>
                    <Columns.Column>
                        <Form.Field>
                            <Form.Label>Callout Cost</Form.Label>
                            <Form.Control className={"has-addons"}>
                                <Form.Input
                                    type={"number"}
                                    name={"callout_cost"}
                                    value={rate.callout_cost}
                                    onChange={onChange}
                                />
                                <Icon align={"left"} className={"small-icon"}>
                                  <FontAwesomeIcon icon={faPoundSign}/>
                                </Icon>
                            </Form.Control>
                        </Form.Field>
                    </Columns.Column>
                    <Columns.Column>
                        <Form.Label>Comments</Form.Label>
                        <Form.Textarea
                            value={rate.comments}
                            name={"comments"}
                            onChange={onChange}
                        />
                    </Columns.Column>
                </Columns>
                <Columns>
                    <Columns.Column>
                        <Button
                            color={"success"}
                            onClick={() => onSave(rate)}
                            fullwidth
                        >
                            Create
                        </Button>
                    </Columns.Column>
                </Columns>
            </Collapsible>
        </Box>
    )


}