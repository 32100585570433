import React, {useState} from "react";
import {Columns, Heading, Modal, Section, Form, Button} from "react-bulma-components";
import {formatDate, movementCost} from "../../utils";
import axios from "axios";
import {NotificationManager} from "react-notifications";
import {useDispatch} from "react-redux";
import {createSetDataFreshAction} from "../../actions/dataActions";
import {asyncContainer, Typeahead} from "react-bootstrap-typeahead";
import MovementRatePicker from "./MovementRatePicker";
import useSelect from "../../hooks/useSelect";
import useButtonSelect from "../../hooks/useButtonSelect";
import MovementTypePicker from "../Settings/MovementType/MovementTypePicker";
import DriverPicker from "../Settings/Driver/DriverPicker";

const AsyncTypeahead = asyncContainer(Typeahead);

export default function CreateMovement({endpoint, autoFill, open, setOpen, dataFresh, movementRateOptions, movementRateObjects, movementTypeOptions, movementTypeObjects, driverOptions, driverObjects}){
    const dispatch = useDispatch();
    const [loading, setLoading] = useState(false);
    const [searchData, setSearchData] = useState([]);
    const [typeahead, setTypeahead] = useState();

    const onChange = e => {
        let updatedMovement = {...movement, [e.target.name] : e.target.value};
        updatedMovement = movementCost(updatedMovement);
        setMovement(updatedMovement);
    };


    const options = [
        {name: "Normal", value: "NORMAL"},
        {name: "Quote", value: "QUOTE"}
    ];

    let customerOptions = (autoFill && autoFill.customers) ?
        autoFill.customers.reduce((prev, current) => {
            return [...prev, {name: current.display_name, value: current.id}]
        }, []) : []

    const customerObjects = (autoFill && autoFill.customers) ? autoFill.customers.reduce((obj, item) => {
      return {
        ...obj,
        [item["id"]]: item
      };
    }, {}) : [];


    const onCustomerSelect = e =>{
        const toUpdate = {
            target: {
                name: e.target.name,
                value: customerObjects[e.target.value]
            }
        };
        onChange(toUpdate);
    }

    const onDriverSelect = e =>{
        const toUpdate = {
            target: {
                name: e.target.name,
                value: driverObjects[e.target.value]
            }
        };
        onChange(toUpdate);
    }

    const [statusOption, statusOptionInput] = useButtonSelect({
        label: "Status",
        initialValue: "NORMAL",
        prop: "movement_status",
        options: options,
        callback: onChange
    });

    const [movement, setMovement] = useState({
        job: autoFill ? autoFill.job : null,
        movement_rate: movementRateObjects[Object.keys(movementRateObjects)[0]], // Finds first element in dictionary
        vehicle: autoFill ? autoFill.vehicle : "",
        customer: (autoFill && autoFill.customers) ? customerObjects[customerOptions[0].value] : null,
        start_address: "",
        end_address: "",
        delivery_date: formatDate(new Date),
        cost: 0,
        comments: "",
        miles: 0,
        cost_override: false,
        movement_status: "NORMAL",
        start_time: "",
        end_time: "",
        movement_type: movementTypeObjects[Object.keys(movementTypeObjects)[0]],
        vehicle_used: null,
        driver: null
    });
    const handleRateChange = e => {
        let toUpdate = {
            target: {
                name: e.target.name,
                value: movementRateObjects[e.target.value]
            }
        }
        onChange(toUpdate);
    }
    const handleTypeChange = e => {
        let toUpdate = {
            target: {
                name: e.target.name,
                value: movementTypeObjects[e.target.value]
            }
        }
        onChange(toUpdate);
    }
    const save = () => {
        let conf = {
            url: endpoint+"movement",
            method: "post",
            data: movement
        };
        console.log(autoFill);
        console.log(movement);
        axios(conf).then(() => {
            dispatch(createSetDataFreshAction(dataFresh + 1));
            setOpen(false);
        }).catch(() => {
            NotificationManager.error("There was an error creating the movement")
        });
    }
    const handleCustomerSearch = query => {
        setLoading(true);
        axios
          .get(endpoint + `contacts?contact_type=CC&search=${query}`)
          .then(resp => {
            setSearchData(resp.data.results);
            setLoading(false);
          });
    };

    const handleVehicleSearch = query => {
        setLoading(true);
        axios
          .get(endpoint + `vehicles?showAll=true&search=${query}`)
          .then(resp => {
            setSearchData(resp.data.results);
            setLoading(false);
          });
    };

    const onCostOverrideCheck = e => {
        let updatedMovement = {...movement, [e.target.name] : !movement.cost_override};
        updatedMovement = movementCost(updatedMovement);
        setMovement(updatedMovement);
    };

    const onCustomerChange = e => {
        let updatedMovement = {...movement, "customer": e[0]}
        setMovement(updatedMovement);
    };

    const onVehicleUsedChange = e => {
        let updatedMovement = {...movement, "vehicle_used": e[0]}
        setMovement(updatedMovement);
    };

    return (
        <Modal
            show={open}
            closeOnBlur
            onClose={()=>{
                setOpen(false)
            }}
        >
            <Modal.Content className={"wider-modal"}>
                <Section style={{background: "white"}}>
                    <Columns>
                        <Columns.Column>
                            <Heading>Create Movement</Heading>
                        </Columns.Column>
                    </Columns>
                    <Columns>
                        <Columns.Column>
                            <MovementTypePicker
                                options={movementTypeOptions ?? {results: []}}
                                value={movement.movement_type}
                                editable={true}
                                label={"Type"}
                                onChange={handleTypeChange}
                            />
                        </Columns.Column>
                        <Columns.Column>
                            <Form.Field>
                                <Form.Label>Vehicle</Form.Label>
                                <Form.Input
                                    name={"vehicle"}
                                    onChange={onChange}
                                    type={"text"}
                                    value={autoFill ? autoFill.vehicle : movement.vehicle}
                                />
                            </Form.Field>
                        </Columns.Column>
                        {!autoFill &&
                            <Columns.Column>
                                <Form.Label>Customer</Form.Label>
                                <AsyncTypeahead
                                  useCache={false}
                                  isLoading={loading}
                                  id="typeahead"
                                  labelKey="display_name"
                                  minLength={2}
                                  name={"customer"}
                                  onSearch={handleCustomerSearch}
                                  onChange={onCustomerChange}
                                  placeholder={`Search Customer`}
                                  options={searchData}
                                  ref={typeahead => setTypeahead(typeahead)}
                                  className="typeahead"
                                />
                            </Columns.Column>
                        }
                        {autoFill && autoFill.customers &&
                            <Columns.Column>
                              <Form.Field>
                                <Form.Label>Customer</Form.Label>
                                <Form.Control>
                                  <Form.Select value={movement.customer ? movement.customer.id : -1} onChange={onCustomerSelect} name={"customer"}>
                                    {customerOptions.map(item => (
                                      <option key={item.name} value={item.value}>
                                        {item.name}
                                      </option>
                                    ))}
                                  </Form.Select>
                                </Form.Control>
                              </Form.Field>
                            </Columns.Column>
                        }
                        <Columns.Column>
                            <Form.Field>
                                <Form.Label>Start Address</Form.Label>
                                <Form.Textarea
                                    name={"start_address"}
                                    onChange={onChange}
                                    value={movement.start_address}
                                />
                            </Form.Field>
                        </Columns.Column>
                        <Columns.Column>
                            <Form.Field>
                                <Form.Label>End Address</Form.Label>
                                <Form.Textarea
                                    name={"end_address"}
                                    onChange={onChange}
                                    value={movement.end_address}
                                />
                            </Form.Field>
                        </Columns.Column>
                    </Columns>
                    <Columns>
                        <Columns.Column>
                            <Form.Field>
                                <Form.Label>Delivery Date</Form.Label>
                                <Form.Input
                                    name={"delivery_date"}
                                    onChange={onChange}
                                    type={"date"}
                                    value={movement.delivery_date}
                                />
                            </Form.Field>
                        </Columns.Column>
                        <Columns.Column>
                            <Form.Label>Vehicle Used</Form.Label>
                            <AsyncTypeahead
                              useCache={false}
                              isLoading={loading}
                              id="typeahead"
                              labelKey="registration"
                              minLength={2}
                              name={"vehicle_used"}
                              onSearch={handleVehicleSearch}
                              onChange={onVehicleUsedChange}
                              placeholder={`Search Vehicle`}
                              options={searchData}
                              ref={typeahead => setTypeahead(typeahead)}
                              className="typeahead"
                            />
                        </Columns.Column>
                        <Columns.Column>
                            <DriverPicker
                                options={driverOptions}
                                label={"Driver"}
                                onChange={onDriverSelect}
                                blank={true}
                                disabled={false}
                                inlineEdit={false}
                                editable={true}
                                value={movement.driver}
                            />
                        </Columns.Column>
                        <Columns.Column>
                            <Form.Field>
                                <Form.Label>Start Time</Form.Label>
                                <Form.Input
                                    name={"start_time"}
                                    onChange={onChange}
                                    type={"time"}
                                    value={movement.start_time}
                                />
                            </Form.Field>
                        </Columns.Column>
                        <Columns.Column>
                            <Form.Field>
                                <Form.Label>End Time</Form.Label>
                                <Form.Input
                                    name={"end_time"}
                                    onChange={onChange}
                                    type={"time"}
                                    value={movement.end_time}
                                />
                            </Form.Field>
                        </Columns.Column>
                        <Columns.Column>
                            <Form.Field>
                                <Form.Label>Comments</Form.Label>
                                <Form.Textarea
                                    name={"comments"}
                                    onChange={onChange}
                                    value={movement.comments}
                                />
                            </Form.Field>
                        </Columns.Column>
                    </Columns>
                    <Columns>
                        <Columns.Column>
                            <MovementRatePicker
                                options={movementRateOptions ?? {results: []}}
                                onChange={handleRateChange}
                                movement={movement}
                                label={"Movement Rate"}
                            />
                        </Columns.Column>
                        <Columns.Column>
                            {statusOptionInput}
                        </Columns.Column>
                        <Columns.Column>
                            <Form.Field>
                                <Form.Label>Cost Override</Form.Label>
                                <Form.Checkbox
                                    name={"cost_override"}
                                    checked={movement.cost_override}
                                    onChange={onCostOverrideCheck}
                                />
                            </Form.Field>
                        </Columns.Column>
                        <Columns.Column>
                            <Form.Field>
                                <Form.Label>Miles</Form.Label>
                                <Form.Input
                                    name={"miles"}
                                    onChange={onChange}
                                    type={"number"}
                                    value={movement.miles}
                                />
                            </Form.Field>
                        </Columns.Column>
                        <Columns.Column>
                            <Form.Field>
                                <Form.Label>Total Cost</Form.Label>
                                <Form.Input
                                    name={"cost"}
                                    onChange={onChange}
                                    type={"number"}
                                    value={movement.cost}
                                />
                            </Form.Field>
                        </Columns.Column>
                    </Columns>
                    <Columns>
                        <Columns.Column>
                            <Button
                                color={"info"}
                                onClick={() => {setOpen(false)}}
                                fullwidth
                            >
                                Cancel
                            </Button>
                        </Columns.Column>
                        <Columns.Column>
                            <Button
                                color={"success"}
                                onClick={save}
                                fullwidth
                            >
                                Save
                            </Button>
                        </Columns.Column>
                    </Columns>
                </Section>
            </Modal.Content>
        </Modal>
    )



}