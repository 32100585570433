import React, {useState} from "react";
import {Box, Button, Columns, Form, Icon} from "react-bulma-components";
import Collapsible from "react-collapsible";
import {faPoundSign} from "@fortawesome/free-solid-svg-icons";
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import TransactionReportingCategoryPicker
    from "../Transactions/TransactionReportingCategories/TransactionReportingCategoryPicker";


export default function NewMovementType({onSave, transactionReportingCategories, transactionReportingObjects}){
    const [type, setType] = useState({
        name: "",
        transaction_reporting_category: transactionReportingObjects[Object.keys(transactionReportingObjects)[0]]
    })
    const onChange = e => {
        let newType = {...type, [e.target.name]: e.target.value}
        setType(newType);
    };

    const onTransactionReportingChange = e => {
        let newType = {...type, ["transaction_reporting_category"]: transactionReportingObjects[e.target.value]}
        setType(newType);
    };
    return (
        <Box>
            <Collapsible
                className={"title"}
                trigger={"Add Movement Type"}
                triggerOpenedClassName={"title"}
            >
                <Columns>
                    <Columns.Column>
                        <Form.Field>
                            <Form.Label>Name</Form.Label>
                            <Form.Input
                                name={"name"}
                                type={"text"}
                                onChange={onChange}
                                value={type.name}
                            />
                        </Form.Field>
                    </Columns.Column>
                    <Columns.Column>
                        <TransactionReportingCategoryPicker
                            options={transactionReportingCategories}
                            onChange={onTransactionReportingChange}
                            inlineEdit={false}
                            editable={true}
                            label={"Reporting Category"}
                            value={type.transaction_reporting_category}
                        />
                    </Columns.Column>
                </Columns>
                <Columns>
                    <Columns.Column>
                        <Button
                            color={"success"}
                            onClick={() => onSave(type)}
                            fullwidth
                        >
                            Create
                        </Button>
                    </Columns.Column>
                </Columns>
            </Collapsible>
        </Box>
    )


}