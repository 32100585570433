import React, { useState } from "react";
import { Box } from "react-bulma-components";
import { Form } from "react-bulma-components";
import { Button } from "react-bulma-components";
import { Pagination } from "react-bulma-components";
import { Columns } from "react-bulma-components";
import {getDataFresh, getDepartmentsData, getQuotesData} from "../../state/selectors";
import { useSelector } from "react-redux";
import Spinner from "react-spinner-material";
import { useQuotesData } from "../../hooks/useQuotesData";
import useSelect from "../../hooks/useSelect";
import {formatDate, readableDate} from "../../utils";
import {useDepartmentsData} from "../../hooks/useDepartmentsData";
import DepartmentPicker from "../Job/DepartmentPicker";
import FilterBlankHeading from "../Invoicing/FilterBlankHeading";
import FilterHeading from "../Invoicing/FilterHeading";
import SearchBar from "../Utils/SearchBar";

function Quote(props) {
  const [page, setPage] = useState(1);
  const [searchText, setSearchText] = useState();
  const [currentSearch, setCurrentSearch] = useState();
  const [selectedDepartment, setSelectedDepartment] = useState();
  const [searchFresh, setSearchFresh] = useState(1);
  let dataFresh = useSelector(getDataFresh);
  const [view, viewInput] = useSelect({
    label: "View",
    options: [
      { name: "Active", value: "active"},
      { name: "To Be Priced", value: "to_be_priced"},
      { name: "Quotes", value: "quotes" },
      { name: "Approved", value: "approved" },
      { name: "All", value: "all"}
    ],
    initialValue: "active",
    testId: "part-view",
    callback: () => {
      setPage(1);
    }
  });
  const departmentsLoaded = useDepartmentsData({}, dataFresh);
  const departments = useSelector(getDepartmentsData);
  let params = { page };
  const [filters, setFilters] = useState({});
  const headings = [
    {
        label: "Job no.",
    },
    {
        label: "Registration",
        field: "vehicle",
        labelField: "vehicle__registration"
    },
    {
        label: "Description"
    },
    {
      label: "Earliest Workshop Date",
    },
    {
      label: "Provisional Start Date",
      field: "provisional_start_date",
      labelField: "provisional_start_date",
      date: true,
      noBlank: true
    },
    {
      label: "Items Not Started"
    },
    {
      label: "Quote Type",
      field: "quote_type",
      labelField: "quote_type"
    },
    {
        label: "Comments"
    },
    {
        label: "Customer",
        field: "customer",
        labelField: "customer__display_name"
    },
    {
        label: "Customer2",
        field: "customer2",
        labelField: "customer2__display_name"
    },
    {
        label: "Make"
    },
    {
        label: "Model"
    }
  ];

  for (let filter in filters) {
    if (filters[filter][0] && filters[filter][0][filter]) {
      params[filter] = filters[filter][0][filter];
    }
  }

  if(selectedDepartment){
    params.department = selectedDepartment;
  }

  if (currentSearch) {
    params.search = currentSearch;
  }
  if (searchFresh){
    params.searchFresh = searchFresh;
  }
  if (view !== "all") {
    params.view = view;
  }

  const handleSearch = () => {
    setPage(1);
    setCurrentSearch(searchText);
    setSearchFresh(searchFresh+1);
  };

  const clear = () => {
    setSearchText("");
    setCurrentSearch("");
    setPage(1);
  };

  const complete = useQuotesData(params);
  const data = useSelector(getQuotesData);
  const onDepartmentChange = (e) => {
    setSelectedDepartment(e.target.value);
  }
  if (!complete || !departmentsLoaded) {
    return (
      <Box>
        <Spinner
          className="spinner-centre"
          size={120}
          spinnerColor={"#3273dc"}
          spinnerWidth={2}
        />
      </Box>
    );
  }

  document.title = "Quotes";

  return (
    <div>
      <Box>
        <Columns>
          {viewInput}
          <Columns.Column>
            <DepartmentPicker
              onDepartmentChange={onDepartmentChange}
              selectedDepartment={selectedDepartment}
              departments={departments}
            />
          </Columns.Column>
          <Columns.Column>
            <Form.Label>Search for a Quote</Form.Label>
            <SearchBar
              handleSearch={handleSearch}
              setSearchText={setSearchText}
              searchText={searchText}
              clear={clear}
            />
          </Columns.Column>
          <Columns.Column>
            <Form.Field>
              <Form.Control>
                <Button
                  onClick={() => props.history.push("/editquote")}
                  color="success"
                  className="is-pulled-right"
                >
                  New Quote+
                </Button>
              </Form.Control>
            </Form.Field>
          </Columns.Column>
        </Columns>
      </Box>
      <Box>
        <h1 className="title">Quotes</h1>
        <table className="table is-hoverable is-fullwidth is-striped">
          <thead>
            <tr className="small-row-black">
              {headings.map(heading => {
                if (!heading.field) {
                  return <th key={heading.label}>{heading.label}</th>;
                }
                if (heading.blank) {
                  return (
                    <FilterBlankHeading
                      key={heading.label}
                      heading={heading}
                      setFilters={f => {
                        setPage(1);
                        setFilters(f);
                      }}
                      filters={filters}
                    />
                  );
                }
                return (
                  <FilterHeading
                    key={heading.label}
                    heading={heading}
                    setFilters={f => {
                      setPage(1);
                      setFilters(f);
                    }}
                    filters={filters}
                    options={data.filter_list[heading.field]}
                  />
                );
              })}
              {view === 'approved' &&
                <th>Approver</th>
              }
            </tr>
          </thead>
          <tbody>
            {data.results.map(value => {
              let highlightClass = "";
              if (value.has_jobs) {
                if (value.ready_for_approval) {
                  highlightClass = "table-highlight-green";
                } else if(value.has_finished_and_not_started_jobs && !value.has_started_job){
                  highlightClass = "table-highlight-red";
                } else {
                  highlightClass = "table-highlight-orange";
                }
              }
              return (
                <tr
                  className={`clickable small-row-black ${highlightClass}`}
                  onClick={() =>
                    window.open(`/editquote/${value.id}`, "_self")
                  }
                  key={value.id}
                >
                  <td>{value.id}</td>
                  <td>{value.vehicle.registration}</td>
                  <td>{value.description}</td>
                  <td>{readableDate(value.earliest_workshop_date)}</td>
                  <td>{readableDate(value.provisional_start_date)}</td>
                  <td>{value.not_started_work_items}</td>
                  <td>{value.quote_type}</td>
                  <Form.Field className="has-addons">
                    <Form.Control>
                      <Form.Textarea
                        rows={2}
                        className="small-row-black min-column-width"
                        type="text"
                        value={value.comments_list.length > 0 ? readableDate(formatDate(new Date(value.comments_list[value.comments_list.length -1].created))) +"\n"+ value.comments_list[value.comments_list.length -1].text : ""}
                        disabled
                        readOnly
                      />
                    </Form.Control>
                  </Form.Field>
                  <td>{value.customer ? value.customer.display_name : ""}</td>
                  <td>
                    {value.customer2
                      ? value.customer2.display_name
                      : ""}
                  </td>
                  <td>{value.vehicle.make ? value.vehicle.make.name : ""}</td>
                  <td>{value.vehicle.model ? value.vehicle.model.name : ""}</td>
                  {view === 'approved' &&
                  <td>{value.approving_user ? `${value.approving_user.first_name} ${value.approving_user.last_name}` : ""}</td>
                  }
                </tr>
              );
            })}
          </tbody>
        </table>
        <Pagination
          showFirstLast={true}
          onChange={setPage}
          current={page}
          total={Math.ceil(data.count / 25)}
        ></Pagination>
      </Box>
    </div>
  );
}

export default Quote;
