import React from "react";
import VehicleForm from "./components/Vehicle/VehicleForm";
import JobForm from "./components/Job/JobForm";
import QuoteForm from "./components/Quote/QuoteForm";
import Nav from "./components/Nav/Nav";
import Home from "./components/Homepage/Home";
import RentalForm from "./components/Rentals/Form";
import RentalPrintView from "./components/Rentals/PrintView";
import Vehicles from "./components/Vehicle/View/VehiclesView";
import Settings from "./components/Settings/Settings";
import Jobs from "./components/Job/View";
import Quotes from "./components/Quote/View";
import Parts from "./components/Part/View";
import Rentals from "./components/Rentals/View";
import { Container } from "react-bulma-components";
import { Section } from "react-bulma-components";
import Contact from "./components/Contact/Form";
import Contacts from "./components/Contact/View";
import Diary from "./components/Diary/View";
import axios from "axios";

import { Route, Redirect } from "react-router-dom";
import InvoicePO from "./components/Invoicing/InvoicePO";
import PartHistory from "./components/PartHistory/PartHistory";
import PartLookup from "./components/PartLookup/PartLookup";
import Inspection from "./components/Inspections/View";
import Payment from "./components/Payment/View";
import "react-notifications/lib/notifications.css";
import { Component } from "react";
import Spinner from "react-spinner-material";
import GoogleLoginButton from "./components/GoogleLogin";
import Transactions from "./components/Transactions/Transactions";
import {
  NotificationContainer,
  NotificationManager
} from "react-notifications";
import Reports from "./components/Reports/View";
import WorkLookup from "./components/WorkLookup/WorkLookup";
import VehicleTrackers from "./components/Tracker/VehicleTrackers";
import RecurringPayment from "./components/RecurringPayment/RecurringPayment";
import DefectForm from "./components/Job/Defects/DefectForm";
import ContactsAccountBalance from "./components/Contact/ContactsAccountBalance";
import WorkLookupForm from "./components/WorkLookup/WorkLookupForm";
import PartEnquiryForm from "./components/Job/Part Enquiry/Form";
import MovementView from "./components/Movement/View";
import KeyFinder from "./components/Vehicle/KeyFinder/Keyfinder";
import InvoiceView from "./components/Movement/Invoicing/InvoiceView";


const routeProps = { endpoint: "/api/" };

function PrivateRoute({ component: Component, ...rest }) {
  if(!localStorage.token) {
    return  <Redirect to={{pathname: "/login"}}/>;
  }

  return <Route
    {...rest}
    render={props =>
      <Component {...rest} {...props} {...routeProps} />
    }
  />;
}

export default class App extends Component {
  constructor(props) {
    super(props);

    this.state = {
      loading: 0
    };
  }

  UNSAFE_componentWillMount() {
    axios.interceptors.request.use(function(config) {
      const token = localStorage.getItem("token");
      config.headers.Authorization = `Token ${token}`;
      return config;
    });

    axios.interceptors.response.use(
      response => {
        if (response.status === 401) {
          window.location.href = "/login";
        }
        // If the request succeeds, we don't have to do anything and just return the response
        if (response.config.method !== "get") {
          NotificationManager.success("Data saved successfully.", "Success!");
        }
        return response;
      },
      response => {
        NotificationManager.error(response.message, "Error!", 10000);
        return Promise.reject(response);
      }
    );
  }

  render = () => (
    <div className="print-container">
      <title>React App</title>
      <Nav />
      {this.state.loading > 0 && (
        <div id="overlay">
          =
          <Spinner
            className="spinner-centre"
            size={120}
            color={"#3273dc"}
            stroke={5}
            visible={this.state.loading > 0}
          />
        </div>
      )}
      <Section>
        <Container className="custom-container" breakpoint="fluid">
          <PrivateRoute exact path={"/"} component={Home} />

          <PrivateRoute exact path="/editvehicle" component={VehicleForm} />
          <PrivateRoute
            exact
            path="/editvehicle/:vehicle"
            component={VehicleForm}
          />

          <PrivateRoute path="/vehicles/" component={Vehicles} />

          <PrivateRoute path="/keys/" component={KeyFinder}/>

          <PrivateRoute path="/accountbalance/" component={ContactsAccountBalance}/>

          <PrivateRoute exact path="/editjob/:job?" component={JobForm} />

          <PrivateRoute exact path="/detailWorkLookup/:workLookup?" component={WorkLookupForm} />

          <PrivateRoute exact path="/defect/:job?" component={DefectForm}/>

          <PrivateRoute exact path="/partenquiry" component={PartEnquiryForm} />

          <PrivateRoute path="/jobs/" component={Jobs} />

          <PrivateRoute path="/quotes/" component={Quotes} />

          <PrivateRoute
            exact
            path="/editquote/:quote?"
            component={QuoteForm}
          />

          <PrivateRoute path="/parts/" component={Parts} />

          <PrivateRoute path="/partHistory/" component={PartHistory} />

          <PrivateRoute path="/partLookup/" component={PartLookup} />

          <PrivateRoute path="/workLookup/" component={WorkLookup} />

          <PrivateRoute
            path="/invoicing/"
            view="invoice"
            component={InvoicePO}
          />

          <PrivateRoute path="/income/" component={Transactions} />
          <PrivateRoute path="/expenses/" component={Transactions} />

          <PrivateRoute path="/po/" view="po" component={InvoicePO} />

          <PrivateRoute exact path="/editrental/" component={RentalForm} />

          <PrivateRoute
            exact
            path="/editrental/:rental"
            component={RentalForm}
          />

          <PrivateRoute path="/rentals" component={Rentals} />

          <PrivateRoute exact path="/editcontact/" component={Contact} />

          <PrivateRoute
            exact
            path="/editcontact/:contact/"
            component={Contact}
          />

          <PrivateRoute path="/contacts/" component={Contacts} />

          <PrivateRoute path="/inspections/" component={Inspection} />

          <PrivateRoute path="/payments/" component={Payment} />

          <PrivateRoute path="/recurringpayments/" component={RecurringPayment} />

          <PrivateRoute path="/diary/" component={Diary} />

          <PrivateRoute
            exact
            path="/printrental/:rental"
            component={RentalPrintView}
          />

          <PrivateRoute
            exact
            path="/reports"
            component={Reports}
            />

          <PrivateRoute exact path="/settings" component={Settings} />

          <PrivateRoute path="/trackers/" component={VehicleTrackers} />

          <PrivateRoute exact path="/movements" component={MovementView} />

          <PrivateRoute exact path="/invoices" component={InvoiceView} />

          <Route
            path={"/login"}
            render={routeProps => <GoogleLoginButton {...routeProps} />}
          />
        </Container>
      </Section>
      <NotificationContainer />
    </div>
  );
}
