import React, {useEffect, useState} from "react";
import { Form } from "react-bulma-components";
import { Button } from "react-bulma-components";
import { Columns } from "react-bulma-components";
import { Box } from "react-bulma-components";
import { Table } from "react-bulma-components";
import {useDispatch, useSelector} from "react-redux";
import {
  getDataFresh, getVehicleTrackerFiguresData,
  getVehicleTrackersData, getVehicleTrackerTypeData,
} from "../../state/selectors";
import { Pagination } from "react-bulma-components";
import VehicleTrackerRow from "./VehicleTrackerRow";
import {
  createSetDataFreshAction,
  createSetVehicleTrackersDataAction,
} from "../../actions/dataActions";
import CreateTrackerModal from "./CreateTrackerModal";
import FilterBlankHeading from "../Invoicing/FilterBlankHeading";
import FilterHeading from "../Invoicing/FilterHeading";
import axios from "axios";
import FAIcon from "../Icon/FAIcon";
import {useVehicleTrackerData} from "../../hooks/useVehicleTrackerData";
import {useVehicleTrackerTypeData} from "../../hooks/useVehicleTrackerTypeData";
import {useVehicleTrackerFigureData} from "../../hooks/useVehicleTrackerFigureData";
import TrackerFigures from "./TrackerFigures";
import useSelect from "../../hooks/useSelect";

function VehicleTrackers(props) {


  const [searchText, setSearchText] = useState("");
  const [selected, setSelected] = useState({});
  const [filters, setFilters] = useState({});
  const [search, setSearch] = useState(null);
  const [page, setPage] = useState(1);
  const dataFresh = useSelector(getDataFresh);
  const dispatch = useDispatch();

  const [createPartModalOpen, setCreatePartModalOpen] = useState(false);
  const [view, viewInput] = useSelect({
    label: "View",
    options: [
        {name: "All", value: "all"},
        {name: "Active", value: "active"},
        {name: "Inactive", value: "inactive"}
    ],
    initialValue: "active"
  });
  const headings = [
    {
      label: "IMEI"
    },
    {
      label: "Vehicle"
    },
    {
      label: "Tracker Type",
      field: "type",
      labelField: "type__name",
      plusBlanks: true,
      objField: true,
      noBlank: false
    },
    {
      label: "Date Assigned",
      field: "date_assigned",
      labelField: "date_assigned",
      date: true
    },
    {
      label: "Date Activated",
      field: "date_activated",
      labelField: "date_activated",
      date: true
    },
    {
      label: "Date Deactivate",
      field: "date_deactivated",
      labelField: "date_deactivated",
      date: true
    }
  ];

  let params = {
    page,
    search,
  };
  if(view){
    params.view = view
  }
  for (let filter in filters) {
    if (filters[filter][0] && filters[filter][0][filter] || (filters[filter][0] && filters[filter][0][filter] === 0)) {
      params[filter] = filters[filter][0][filter];
    }
  }
  const vehicleTrackersLoaded = useVehicleTrackerData(params, dataFresh);
  const trackerTypesLoaded = useVehicleTrackerTypeData({}, dataFresh);


  const data = useSelector(getVehicleTrackersData);
  const trackerTypes = useSelector(getVehicleTrackerTypeData);

  const updateValue = (index, newTracker) => {
    let currentData = { ...data };
    currentData.results[index] = {...newTracker};
    dispatch(createSetVehicleTrackersDataAction(currentData));
  };

  if (!vehicleTrackersLoaded || !trackerTypesLoaded) return <div>Loading</div>;

  const typeObjects = trackerTypes.results.reduce((obj, item) => {
      return {
        ...obj,
        [item["id"]]: item
      };
  }, {});


  document.title = "Vehicle Trackers";

  return (
    <div>
      <TrackerFigures dataFresh={dataFresh}/>
      <Box>
        <Columns>
          <Columns.Column>
            <Form.Label>Tracker Search</Form.Label>
            <Form.Field className="has-addons">
              <Form.Control>
                <Form.Input
                  onChange={e => {
                    setSearchText(e.target.value);
                  }}
                  onKeyPress={e => (e.key === 'Enter' || e.keyCode === 13) && setSearch(searchText)}
                  data-testid="part-search"
                  name="search_text"
                  type="text"
                  placeholder="Search"
                  value={searchText}
                />
              </Form.Control>
              <Form.Control>
                <Button
                  data-testid="search-button"
                  color="success"
                  onClick={() => {
                    setSearch(searchText);
                  }}
                >
                  Search
                </Button>
              </Form.Control>
              <Form.Control>
                <Button
                  color="warning"
                  onClick={() => {
                    setSearch("");
                    setSearchText("");
                  }}
                >
                  Clear
                </Button>
              </Form.Control>
            </Form.Field>
          </Columns.Column>
          {viewInput}
          <Columns.Column>
            <Form.Field>
              <Form.Control>
                <Button
                  onClick={() => setCreatePartModalOpen(true)}
                  color="success"
                  className="is-pulled-right"
                >
                  New Tracker +
                </Button>
              </Form.Control>
            </Form.Field>
          </Columns.Column>
        </Columns>
      </Box>
      <Box>
        <Table>
          <thead>
            <tr className="small-row-black">
              {headings.map(heading => {
                if (!heading.field) {
                  return <th key={heading.label}>{heading.label}</th>;
                }
                if (heading.blank) {
                  return (
                    <FilterBlankHeading
                      key={heading.label}
                      heading={heading}
                      setFilters={f => {
                        setPage(1);
                        setFilters(f);
                      }}
                      filters={filters}
                    />
                  );
                }
                return (
                  <FilterHeading
                    key={heading.label}
                    heading={heading}
                    setFilters={f => {
                      console.log(f);
                      setPage(1);
                      setFilters(f);
                    }}
                    filters={filters}
                    options={data.filter_list[heading.field]}
                  />
                );
              })}
            </tr>
          </thead>
          <tbody>
            {data.results.map((tracker, index) => (
              <VehicleTrackerRow
                key={tracker.id}
                tracker={tracker}
                endpoint={props.endpoint}
                updateValue={newTracker => updateValue(index, newTracker)}
                trackerTypes={trackerTypes}
                trackerTypeObjects={typeObjects}
              />
            ))}
          </tbody>
        </Table>
        <Pagination
          showFirstLast={true}
          onChange={setPage}
          current={page}
          total={Math.ceil(data.count / 25)}
        ></Pagination>
      </Box>
      <CreateTrackerModal
        open={createPartModalOpen}
        setOpen={setCreatePartModalOpen}
        endpoint={props.endpoint}
        types={trackerTypes}
        typeObjects={typeObjects}
      />
    </div>
  );
}

export default VehicleTrackers;
