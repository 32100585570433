import React, {useState} from "react";
import useEditableTableDateCell from "../../../hooks/useEditableTableDateCell";
import useEditableTableTextCell from "../../../hooks/useEditableTableTextCell";
import useEditableTableTextAreaCell from "../../../hooks/useEditableTableTextAreaCell";
import useEditableTableCostCell from "../../../hooks/useEditableTableCostCell";
import {Button, Form} from "react-bulma-components";
import FAIcon from "../../Icon/FAIcon";


export default function InvoiceRow({invoice, onChange, onSave, index, onDelete, onApprove, showApproved, onReady}){
    let [editable, setEditable] = useState(false);
    let type = invoice.movement ? "Movement "+invoice.movement.id : "User Created";
    return(
        <tr>
            <td>
                {useEditableTableTextCell({
                    propName: "id",
                    value: invoice.id,
                    disabled: true
                })}
            </td>
            <td>
                {useEditableTableTextCell({
                    propName: "customer",
                    value: invoice.customer.display_name,
                    disabled: true
                })}
            </td>
            <td>
                {useEditableTableTextCell({
                    propName: "",
                    value: type,
                    disabled: true
                })}
            </td>
            <td>
                {useEditableTableCostCell({
                    propName: "amount",
                    value: invoice.amount,
                    disabled: invoice.approved,
                    inlineEdit: true,
                    editable,
                    setEditable,
                    onChange: (e) => onChange(e, index),
                    onSave: () => {
                        onSave(invoice);
                        setEditable(false)
                    }
                })}
            </td>
            <td>
                {useEditableTableCostCell({
                    propName: "no_vat_amount",
                    value: invoice.no_vat_amount,
                    disabled: invoice.approved,
                    inlineEdit: true,
                    editable,
                    setEditable,
                    onChange: (e) => onChange(e, index),
                    onSave: () => {
                        onSave(invoice);
                        setEditable(false)
                    }
                })}
            </td>
            <td>
                {useEditableTableCostCell({
                    propName: "vat",
                    value: invoice.amount * 0.2,
                    disabled: true
                })}
            </td>
            <td>
                {useEditableTableCostCell({
                    propName: "total",
                    value: (parseFloat(invoice.amount) * 1.2) + parseFloat(invoice.no_vat_amount),
                    disabled: true
                })}
            </td>
            <td>
                {useEditableTableDateCell({
                    propName: "invoice_date",
                    value: invoice.invoice_date,
                    onChange: (e) => onChange(e, index),
                    onSave: () => {
                        onSave(invoice);
                        setEditable(false)
                    },
                    editable,
                    setEditable,
                    inlineEdit: true,
                    disabled: invoice.approved
                })}
            </td>
            <td>
                {useEditableTableDateCell({
                    propName: "payment_date",
                    value: invoice.payment_date,
                    onChange: (e) => onChange(e, index),
                    onSave: () => {
                        onSave(invoice);
                        setEditable(false)
                    },
                    editable,
                    setEditable,
                    inlineEdit: true,
                    disabled: invoice.approved
                })}
            </td>
            <td>
                {useEditableTableTextCell({
                    propName: "invoice_number",
                    value: invoice.invoice_number,
                    onChange: (e) => onChange(e, index),
                    onSave: () => {
                        onSave(invoice);
                        setEditable(false)
                    },
                    editable,
                    setEditable,
                    inlineEdit: true,
                    disabled: invoice.approved
                })}
            </td>
            <td>
                {useEditableTableTextAreaCell({
                    propName: "description",
                    value: invoice.description,
                    onChange: (e) => onChange(e, index),
                    onSave: () => {
                        onSave(invoice);
                        setEditable(false)
                    },
                    editable,
                    setEditable,
                    inlineEdit: true,
                    disabled: invoice.approved
                })}
            </td>
            <td>
                {useEditableTableTextAreaCell({
                    propName: "invoice_comments",
                    value: invoice.comments,
                    onChange: (e) => onChange(e, index),
                    onSave: () => {
                        onSave(invoice);
                        setEditable(false)
                    },
                    editable,
                    setEditable,
                    inlineEdit: true,
                    disabled: invoice.approved
                })}
            </td>
            {showApproved &&
                <td>
                    <Form.Input
                        disabled={true}
                        value={invoice.approver ? invoice.approver.first_name+" "+invoice.approver.last_name : ""}
                    />
                </td>
            }
            {!showApproved && !invoice.approved && !invoice.ready_to_invoice &&
                 <td>
                    <Button
                        color={"info"}
                        onClick={() => onReady(invoice)}
                    >
                        <FAIcon size="small" icon={["fas", "check"]}/>
                    </Button>
                 </td>
            }
            {!showApproved && !invoice.approved && invoice.ready_to_invoice &&
                <td>
                    <Button
                        color={"success"}
                        onClick={() => onApprove(invoice)}
                    >
                        <FAIcon size="small" icon={["fas", "check"]}/>
                    </Button>
                </td>
            }
        </tr>
    )


}